<template>
    <component :is="SkinComponent" v-model="form" :code="code" @save="save"></component>
</template>

<script>
import api from "@/api";
import { APPLICATION_STATUS } from '@/assets/variables';

export default {
    props: {
        skin: { type: String, default: null },
        code: { type: String, default: null },
    },

    data() {
        return {
            form: {},
        };
    },

    computed: {
        SkinComponent() {
            return () => import(`./skin/studio-apply/input.vue`);
        },
    },

    mounted() {
    },

    methods: {
        async init() {
        },
        async save(value) {
            //sync
            this.form = { ...value };
            try{
                if(!this.validation()) return;
                await api.v1.apply.applications.post({...this.form, status: APPLICATION_STATUS.WAITING.value});
                // await api.v1.forms.post(this.form);

                alert("신청이 완료되었습니다.");
                this.$router.go(-1);
            } catch(error){
                console.error(error.message);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        validation(){
            if(!this.form.name) throw new Error("이름을 입력해주세요");
            if(!this.form.phone) throw new Error("연락처를 입력해주세요");
            if(!this.form.applyDate) throw new Error("날짜를 선택해주세요");
            if(!this.form.applyTime) throw new Error("시간을 선택해주세요");
            if(this.form.equipments?.length < 1) throw new Error("장비를 선택해주세요");
            if(!this.form.purpose) throw new Error("신청 사유을 입력해주세요");

            return true;
        },
    },
};
</script>

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-primary', {
    scopedSlots: _vm._u([{
      key: "contentsHead",
      fn: function () {
        return [_c('sub-head-primary', {
          attrs: {
            "itemTitle": "공용재단실",
            "tabActive": "신청방법",
            "bg": "/images/sub/sub-visual4.jpg"
          },
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v("공용재단실")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('page-section-primary', {
    staticClass: "page-section--first page-section--xs",
    attrs: {
      "titWrapClass": "text-center"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("신청방법")];
      },
      proxy: true
    }])
  }, [_c('card-secondary', {
    staticClass: "v-card--none-active-bg cursor-default text-center",
    attrs: {
      "cardInnerSize": "card-inner--md"
    }
  }, [_c('tit-primary', {
    staticClass: "font-weight-regular line-height-15 mb-6px mb-md-24px",
    attrs: {
      "titSize": "tit--sm"
    }
  }, [_vm._v("동대문구에 사업장이 소재한 의류제조업체 및 디자이너 등 "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v("자동재단 및 패턴CAD 서비스를 희망하는 "), _c('b', {
    staticClass: "primary--text"
  }, [_vm._v("패션소공인이면 누구나")])]), _c('txt-primary', {
    staticClass: "txt--lg line-height-15"
  }, [_vm._v(" 동대문구 패션 봉제 산업의 다각적인 지원과 교육을 통해 기술 경쟁력 향상 및 역량강화로 지역경제 활성화에 기여하고자 합니다. ")])], 1)], 1), _c('page-section-secondary', {
    staticClass: "page-section--xs pt-0",
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("운영안내")];
      },
      proxy: true
    }])
  }, [_c('card-primary', {
    staticClass: "border-4",
    attrs: {
      "cardInnerSize": "card-inner--sm"
    }
  }, [_c('u-txt-items', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('u-txt-items', {
    attrs: {
      "txtItem": "",
      "titCols": "auto",
      "txtCols": "",
      "size": "lg"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("이용시간")];
      },
      proxy: true
    }, {
      key: "txt",
      fn: function () {
        return [_vm._v("평일 오전9시 ~ 오후6시 (토,일,공휴일 휴무)")];
      },
      proxy: true
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('u-txt-items', {
    attrs: {
      "txtItem": "",
      "titCols": "auto",
      "txtCols": "",
      "size": "lg"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("장소")];
      },
      proxy: true
    }, {
      key: "txt",
      fn: function () {
        return [_vm._v(" 동대문구 답십리로38길 19, B동 1층"), _c('span', {
          staticClass: "primary--text"
        }, [_vm._v("(답십리)")]), _c('br'), _vm._v(" 동대문구 한빛로 62, 4층"), _c('span', {
          staticClass: "accent--text"
        }, [_vm._v("(용신동)")])];
      },
      proxy: true
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('u-txt-items', {
    attrs: {
      "txtItem": "",
      "titCols": "auto",
      "txtCols": "",
      "size": "lg"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("문의처")];
      },
      proxy: true
    }, {
      key: "txt",
      fn: function () {
        return [_vm._v(" 02-2212-5581"), _c('span', {
          staticClass: "primary--text"
        }, [_vm._v("(답십리)")]), _c('br'), _vm._v(" 02-6235-2202"), _c('span', {
          staticClass: "accent--text"
        }, [_vm._v("(용신동)")])];
      },
      proxy: true
    }])
  })], 1)], 1)], 1)], 1), _c('page-section-secondary', {
    staticClass: "page-section--last page-section--xs pt-0",
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("이용절차")];
      },
      proxy: true
    }, {
      key: "pageSectionFoot",
      fn: function () {
        return [_c('page-section-primary', {
          staticClass: "page-section--xxs py-0"
        }, [_c('div', {
          staticClass: "overflow-hidden"
        }, [_c('v-row', {
          staticClass: "row--xl step-border"
        }, [_c('v-col', {
          attrs: {
            "cols": "12",
            "sm": "6",
            "md": "4",
            "lg": "3"
          }
        }, [_c('card-primary', {
          staticClass: "border-4 text-center",
          attrs: {
            "cardInnerSize": "card-inner--md",
            "innerClass": "px-10px"
          }
        }, [_c('chip-primary4', {
          staticClass: "mb-10px mb-md-18px"
        }, [_vm._v("STEP 1")]), _c('tit-primary', {
          staticClass: "font-weight-semibold letter-spacing-n05 mb-4px",
          attrs: {
            "titSize": "tit--sm"
          }
        }, [_vm._v("재단 스케쥴표 확인")]), _c('txt-primary', {
          staticClass: "txt--lg txt--light line-height-15"
        }, [_vm._v("인터넷 홈페이지를 통해 "), _c('br', {
          staticClass: "d-none d-md-block"
        }), _vm._v("재단 스케쥴표 확인")])], 1), _c('card-secondary', {
          staticClass: "text-center mt-4px",
          attrs: {
            "cardInnerSize": "card-inner--sm",
            "color": "primary lighten-5"
          }
        }, [_c('txt-primary', {
          staticClass: "primary--text font-weight-bold"
        }, [_vm._v("의뢰업체")])], 1)], 1), _c('v-col', {
          attrs: {
            "cols": "12",
            "sm": "6",
            "md": "4",
            "lg": "3"
          }
        }, [_c('card-primary', {
          staticClass: "border-4 text-center",
          attrs: {
            "cardInnerSize": "card-inner--md",
            "innerClass": "px-10px"
          }
        }, [_c('chip-primary4', {
          staticClass: "mb-10px mb-md-18px"
        }, [_vm._v("STEP 2")]), _c('tit-primary', {
          staticClass: "font-weight-semibold letter-spacing-n05 mb-4px",
          attrs: {
            "titSize": "tit--sm"
          }
        }, [_vm._v("서류 제출")]), _c('txt-primary', {
          staticClass: "txt--lg txt--light line-height-15"
        }, [_vm._v("재단의뢰신청서, 사업장 "), _c('br', {
          staticClass: "d-none d-md-block"
        }), _vm._v("소재지 증빙 서류(최초 1회)제출")])], 1), _c('card-secondary', {
          staticClass: "text-center mt-4px",
          attrs: {
            "cardInnerSize": "card-inner--sm",
            "color": "primary lighten-5"
          }
        }, [_c('txt-primary', {
          staticClass: "primary--text font-weight-bold"
        }, [_vm._v("재단실장, 의뢰업체")])], 1)], 1), _c('v-col', {
          attrs: {
            "cols": "12",
            "sm": "6",
            "md": "4",
            "lg": "3"
          }
        }, [_c('card-primary', {
          staticClass: "border-4 text-center",
          attrs: {
            "cardInnerSize": "card-inner--md",
            "innerClass": "px-10px"
          }
        }, [_c('chip-primary4', {
          staticClass: "mb-10px mb-md-18px"
        }, [_vm._v("STEP 3")]), _c('tit-primary', {
          staticClass: "font-weight-semibold letter-spacing-n05 mb-4px",
          attrs: {
            "titSize": "tit--sm"
          }
        }, [_vm._v("재단 상담")]), _c('txt-primary', {
          staticClass: "txt--lg txt--light line-height-15"
        }, [_vm._v("샘플, 패턴, 작업지시서 지참")])], 1), _c('card-secondary', {
          staticClass: "text-center mt-4px",
          attrs: {
            "cardInnerSize": "card-inner--sm",
            "color": "primary lighten-5"
          }
        }, [_c('txt-primary', {
          staticClass: "primary--text font-weight-bold"
        }, [_vm._v("재단실장, 의뢰업체")])], 1)], 1), _c('v-col', {
          attrs: {
            "cols": "12",
            "sm": "6",
            "md": "4",
            "lg": "3"
          }
        }, [_c('card-primary', {
          staticClass: "border-4 text-center",
          attrs: {
            "cardInnerSize": "card-inner--md",
            "innerClass": "px-10px"
          }
        }, [_c('chip-primary4', {
          staticClass: "mb-10px mb-md-18px"
        }, [_vm._v("STEP 4")]), _c('tit-primary', {
          staticClass: "font-weight-semibold letter-spacing-n05 mb-4px",
          attrs: {
            "titSize": "tit--sm"
          }
        }, [_vm._v("원단 입고")]), _c('txt-primary', {
          staticClass: "txt--lg txt--light line-height-15"
        }, [_vm._v("원단 상·하차 "), _c('br', {
          staticClass: "d-none d-md-block"
        }), _vm._v("의뢰업체에서 부담")])], 1), _c('card-secondary', {
          staticClass: "text-center mt-4px",
          attrs: {
            "cardInnerSize": "card-inner--sm",
            "color": "primary lighten-5"
          }
        }, [_c('txt-primary', {
          staticClass: "primary--text font-weight-bold"
        }, [_vm._v("의뢰업체")])], 1)], 1), _c('v-col', {
          attrs: {
            "cols": "12",
            "sm": "6",
            "md": "4",
            "lg": "3"
          }
        }, [_c('card-primary', {
          staticClass: "border-4 text-center",
          attrs: {
            "cardInnerSize": "card-inner--md",
            "innerClass": "px-10px"
          }
        }, [_c('chip-primary4', {
          staticClass: "mb-10px mb-md-18px"
        }, [_vm._v("STEP 5")]), _c('tit-primary', {
          staticClass: "font-weight-semibold letter-spacing-n05 mb-4px",
          attrs: {
            "titSize": "tit--sm"
          }
        }, [_vm._v("신청접수 완료")]), _c('txt-primary', {
          staticClass: "txt--lg txt--light line-height-15"
        }, [_vm._v("재단서비스 신청현황 "), _c('br', {
          staticClass: "d-none d-md-block"
        }), _vm._v("홈페이지 게시")])], 1), _c('card-secondary', {
          staticClass: "text-center mt-4px",
          attrs: {
            "cardInnerSize": "card-inner--sm",
            "color": "primary lighten-5"
          }
        }, [_c('txt-primary', {
          staticClass: "primary--text font-weight-bold"
        }, [_vm._v("재단실장")])], 1)], 1), _c('v-col', {
          attrs: {
            "cols": "12",
            "sm": "6",
            "md": "4",
            "lg": "3"
          }
        }, [_c('card-primary', {
          staticClass: "border-4 text-center",
          attrs: {
            "cardInnerSize": "card-inner--md",
            "innerClass": "px-10px"
          }
        }, [_c('chip-primary4', {
          staticClass: "mb-10px mb-md-18px"
        }, [_vm._v("STEP 6")]), _c('tit-primary', {
          staticClass: "font-weight-semibold letter-spacing-n05 mb-4px",
          attrs: {
            "titSize": "tit--sm"
          }
        }, [_vm._v("패턴, 마카 작업")]), _c('txt-primary', {
          staticClass: "txt--lg txt--light line-height-15"
        }, [_vm._v("패턴입력 및 마카작업 "), _c('br', {
          staticClass: "d-none d-md-block"
        }), _vm._v("(CAD 활용)")])], 1), _c('card-secondary', {
          staticClass: "text-center mt-4px",
          attrs: {
            "cardInnerSize": "card-inner--sm",
            "color": "primary lighten-5"
          }
        }, [_c('txt-primary', {
          staticClass: "primary--text font-weight-bold"
        }, [_vm._v("재단부실장")])], 1)], 1), _c('v-col', {
          attrs: {
            "cols": "12",
            "sm": "6",
            "md": "4",
            "lg": "3"
          }
        }, [_c('card-primary', {
          staticClass: "border-4 text-center",
          attrs: {
            "cardInnerSize": "card-inner--md",
            "innerClass": "px-10px"
          }
        }, [_c('chip-primary4', {
          staticClass: "mb-10px mb-md-18px"
        }, [_vm._v("STEP 7")]), _c('tit-primary', {
          staticClass: "font-weight-semibold letter-spacing-n05 mb-4px",
          attrs: {
            "titSize": "tit--sm"
          }
        }, [_vm._v("재단 작업")]), _c('txt-primary', {
          staticClass: "txt--lg txt--light line-height-15"
        }, [_vm._v("재단 작업 "), _c('br', {
          staticClass: "d-none d-md-block"
        }), _vm._v("(CAM 활용)")])], 1), _c('card-secondary', {
          staticClass: "text-center mt-4px",
          attrs: {
            "cardInnerSize": "card-inner--sm",
            "color": "primary lighten-5"
          }
        }, [_c('txt-primary', {
          staticClass: "primary--text font-weight-bold"
        }, [_vm._v("재단실장")])], 1)], 1), _c('v-col', {
          attrs: {
            "cols": "12",
            "sm": "6",
            "md": "4",
            "lg": "3"
          }
        }, [_c('card-primary', {
          staticClass: "border-4 text-center",
          attrs: {
            "cardInnerSize": "card-inner--md",
            "innerClass": "px-10px"
          }
        }, [_c('chip-primary4', {
          staticClass: "mb-10px mb-md-18px"
        }, [_vm._v("STEP 8")]), _c('tit-primary', {
          staticClass: "font-weight-semibold letter-spacing-n05 mb-4px",
          attrs: {
            "titSize": "tit--sm"
          }
        }, [_vm._v("재단물 출고")]), _c('txt-primary', {
          staticClass: "txt--lg txt--light line-height-15"
        }, [_vm._v("재단물 확인, 출고")])], 1), _c('card-secondary', {
          staticClass: "text-center mt-4px",
          attrs: {
            "cardInnerSize": "card-inner--sm",
            "color": "primary lighten-5"
          }
        }, [_c('txt-primary', {
          staticClass: "primary--text font-weight-bold"
        }, [_vm._v("재단실장, 의뢰업체")])], 1)], 1)], 1)], 1), _c('div', {
          staticClass: "mt-16px mt-md-24px"
        }, [_c('txt-primary', {
          staticClass: "txt--lg line-height-15 txt--light"
        }, [_vm._v(" 공용재단실 신청 및 작업 현황은 동대문구 패션봉제지원센터 홈페이지(ddmsfcenter.com) > 공용재단실 > 스케쥴 "), _c('br', {
          staticClass: "d-none d-md-block"
        }), _vm._v(" 또는 CUTZ 어플(구글 플레이스토어 검색)에서 확인하실 수 있습니다. ")])], 1)]), _c('page-section-primary', {
          staticClass: "page-section--xxs pb-0"
        }, [_c('card-primary', {
          staticClass: "border-4",
          attrs: {
            "cardInnerSize": "card-inner--md"
          }
        }, [_c('v-row', {
          staticClass: "row--xs"
        }, [_c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('u-txt-items', {
          attrs: {
            "itemsSize": "row--xs"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('u-txt-items', {
          staticClass: "ma-n2px",
          attrs: {
            "txtItem": "",
            "titCols": "auto",
            "txtCols": "",
            "colClass": "pa-2px",
            "titWidth": "",
            "titClass": "txt--lg line-height-15",
            "txtClass": "txt--lg line-height-15"
          },
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v("1.")];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return [_vm._v("재단실 이용 신청 시, 재단의뢰 신청서와 사업장 소재지가 동대문구임을 증빙할 수 있는 서류(사업자등록증 등/최초1회)를 제출해야 합니다.")];
            },
            proxy: true
          }])
        })], 1)], 1)], 1), _c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('u-txt-items', {
          attrs: {
            "itemsSize": "row--xs"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('u-txt-items', {
          staticClass: "ma-n2px",
          attrs: {
            "txtItem": "",
            "titCols": "auto",
            "txtCols": "",
            "colClass": "pa-2px",
            "titWidth": "",
            "titClass": "txt--lg line-height-15",
            "txtClass": "txt--lg line-height-15"
          },
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v("2.")];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return [_vm._v("작업지시서, 패턴, 샘플 및 원단 입고가 모두 완료된 업체부터 작업을 진행합니다.")];
            },
            proxy: true
          }])
        })], 1)], 1)], 1), _c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('u-txt-items', {
          attrs: {
            "itemsSize": "row--xs"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('u-txt-items', {
          staticClass: "ma-n2px",
          attrs: {
            "txtItem": "",
            "titCols": "auto",
            "txtCols": "",
            "colClass": "pa-2px",
            "titWidth": "",
            "titClass": "txt--lg line-height-15",
            "txtClass": "txt--lg line-height-15"
          },
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v("3.")];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return [_vm._v(" 직기는 겉감 위주(안감, 심지 불가), 다이마루는 몸판 위주(시보리 불가)로 재단이 가능합니다."), _c('br'), _c('span', {
                staticClass: "primary--text"
              }, [_vm._v("※ 비수기(1-2월, 7-8월)에는 재단실과 협의")])];
            },
            proxy: true
          }])
        })], 1)], 1)], 1), _c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('u-txt-items', {
          attrs: {
            "itemsSize": "row--xs"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('u-txt-items', {
          staticClass: "ma-n2px",
          attrs: {
            "txtItem": "",
            "titCols": "auto",
            "txtCols": "",
            "colClass": "pa-2px",
            "titWidth": "",
            "titClass": "txt--lg line-height-15",
            "txtClass": "txt--lg line-height-15"
          },
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v("4.")];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return [_vm._v(" 원단은 롤(roll) 형태로 입고하여야 하며, 원단 상․하차는 의뢰업체에서 부담합니다."), _c('br'), _c('span', {
                staticClass: "primary--text"
              }, [_vm._v("※ 원단 상·하차는 재단실에서 수행하지 않습니다.")])];
            },
            proxy: true
          }])
        })], 1)], 1)], 1), _c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('u-txt-items', {
          attrs: {
            "itemsSize": "row--xs"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('u-txt-items', {
          staticClass: "ma-n2px",
          attrs: {
            "txtItem": "",
            "titCols": "auto",
            "txtCols": "",
            "colClass": "pa-2px",
            "titWidth": "",
            "titClass": "txt--lg line-height-15",
            "txtClass": "txt--lg line-height-15"
          },
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v("5.")];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return [_vm._v(" 한 업체당 월 6회 이내로 재단실 이용이 가능합니다. ")];
            },
            proxy: true
          }])
        })], 1)], 1)], 1), _c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('u-txt-items', {
          attrs: {
            "itemsSize": "row--xs"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('u-txt-items', {
          staticClass: "ma-n2px",
          attrs: {
            "txtItem": "",
            "titCols": "auto",
            "txtCols": "",
            "colClass": "pa-2px",
            "titWidth": "",
            "titClass": "txt--lg line-height-15",
            "txtClass": "txt--lg line-height-15"
          },
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v("6.")];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return [_vm._v(" 하루 1스타일당 아래 표의 기준에 따라 재단해드립니다. ")];
            },
            proxy: true
          }])
        })], 1)], 1)], 1), _c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('table-primary', {
          scopedSlots: _vm._u([{
            key: "header",
            fn: function () {
              return [_c('thead', [_c('tr', [_c('th-primary', {
                staticStyle: {
                  "width": "35%"
                },
                attrs: {
                  "colspan": "2"
                }
              }, [_vm._v("원단 구분")]), _c('th-primary', {
                staticStyle: {
                  "width": "20%"
                }
              }, [_vm._v("재단 가능량")]), _c('th-primary', {
                staticStyle: {
                  "width": "45%"
                }
              }, [_vm._v("비고")])], 1)])];
            },
            proxy: true
          }, {
            key: "body",
            fn: function () {
              return [_c('tbody', [_c('tr', [_c('td-primary', {
                staticClass: "grey lighten-5",
                attrs: {
                  "rowspan": "2"
                }
              }, [_vm._v("다이마루"), _c('br'), _vm._v("(티셔츠 류)")]), _c('td-primary', [_vm._v("두꺼운")]), _c('td-primary', [_vm._v("500장")]), _c('td-primary', {
                attrs: {
                  "rowspan": "6"
                }
              }, [_c('div', {
                staticClass: "text-left py-16px px-lg-20px"
              }, [_c('chip-primary2', [_vm._v("재단 불가능한 경우")]), _c('div', {
                staticClass: "mt-8px mt-md-16px"
              }, [_c('v-row', {
                staticClass: "ma-n2px"
              }, [_c('v-col', {
                staticClass: "pa-2px",
                attrs: {
                  "cols": "12"
                }
              }, [_vm._v(" 1.체크, 스트라이프, 커트지 등 무늬 원단(무지 개념인 경우 가능) ")]), _c('v-col', {
                staticClass: "pa-2px",
                attrs: {
                  "cols": "12"
                }
              }, [_vm._v(" 2.가죽, 레쟈, 코팅원단 등 바람이 통과하지 못하는 소재 원단 ")]), _c('v-col', {
                staticClass: "pa-2px",
                attrs: {
                  "cols": "12"
                }
              }, [_vm._v(" 3.자투리 원단, 주름 원단 등 불규칙한 소재 원단 ")]), _c('v-col', {
                staticClass: "pa-2px",
                attrs: {
                  "cols": "12"
                }
              }, [_vm._v(" 4.배색이 1가지 이상인 경우 ")])], 1)], 1)], 1)])], 1), _c('tr', [_c('td-primary', [_vm._v("얇은")]), _c('td-primary', [_vm._v("1,000장")])], 1), _c('tr', [_c('td-primary', {
                staticClass: "grey lighten-5",
                attrs: {
                  "rowspan": "3"
                }
              }, [_vm._v("직기"), _c('br'), _vm._v("(셔츠류)")]), _c('td-primary', [_vm._v("두꺼운")]), _c('td-primary', [_vm._v("300장")])], 1), _c('tr', [_c('td-primary', [_vm._v("얇은")]), _c('td-primary', [_vm._v("500장")])], 1), _c('tr', [_c('td-primary', [_vm._v("특수원단(고밀도 원단)")]), _c('td-primary', [_vm._v("재단실과 업체 협의")])], 1), _c('tr', [_c('td-primary', {
                staticClass: "grey lighten-5",
                attrs: {
                  "rowspan": "3"
                }
              }, [_vm._v("기타")]), _c('td-primary', [_vm._v("의류 외 재단물")]), _c('td-primary', [_vm._v("재단실과 업체 협의")])], 1)])];
            },
            proxy: true
          }])
        })], 1), _c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('u-txt-items', {
          attrs: {
            "itemsSize": "row--xs"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('u-txt-items', {
          staticClass: "ma-n2px",
          attrs: {
            "txtItem": "",
            "titCols": "auto",
            "txtCols": "",
            "colClass": "pa-2px",
            "titWidth": "",
            "titClass": "txt--lg line-height-15",
            "txtClass": "txt--lg line-height-15"
          },
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v("7.")];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return [_vm._v(" 예약한 일자에 아무런 통보 없이 패턴, 원단 등 미입고하여 예약 취소되는 경우에는 그날로부터 7영업일동안 재단예약을 하실 수 없습니다. ")];
            },
            proxy: true
          }])
        })], 1)], 1)], 1), _c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('u-txt-items', {
          attrs: {
            "itemsSize": "row--xs"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('u-txt-items', {
          staticClass: "ma-n2px",
          attrs: {
            "txtItem": "",
            "titCols": "auto",
            "txtCols": "",
            "colClass": "pa-2px",
            "titWidth": "",
            "titClass": "txt--lg line-height-15",
            "txtClass": "txt--lg line-height-15"
          },
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v("8.")];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return [_vm._v(" 재단스케쥴 변경 요청은 불가합니다. ")];
            },
            proxy: true
          }])
        })], 1)], 1)], 1), _c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('u-txt-items', {
          attrs: {
            "itemsSize": "row--xs"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('u-txt-items', {
          staticClass: "ma-n2px",
          attrs: {
            "txtItem": "",
            "titCols": "auto",
            "txtCols": "",
            "colClass": "pa-2px",
            "titWidth": "",
            "titClass": "txt--lg line-height-15",
            "txtClass": "txt--lg line-height-15"
          },
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v("9.")];
            },
            proxy: true
          }, {
            key: "txt",
            fn: function () {
              return [_vm._v(" 이용수칙을 준수하지 않는 업체는 재단실 이용이 제한될 수 있습니다. ")];
            },
            proxy: true
          }])
        })], 1)], 1)], 1)], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div class="thumbs-slider">
        <v-container>
            <div class="thumbs-slider-top">
                <swiper class="swiper" :options="swiperOptionTop" ref="swiperTop">
                    <swiper-slide v-for="(item, index) in items" :key="index">
                        <v-card flat rounded="0" class="thumbs-slider__inner">
                            <div class="thumbs-slider__img" :style="'background-image:url(' + item.img + ')'" />
                        </v-card>
                    </swiper-slide>
                </swiper>
            </div>
        </v-container>

        <div class="thumbs-slider-thumbs">
            <swiper class="swiper" :options="swiperOptionThumbs" ref="swiperThumbs">
                <swiper-slide v-for="(item, index) in items" :key="index">
                    <v-card flat rounded="0" class="thumbs-slider__inner">
                        <div class="thumbs-slider__img" :style="'background-image:url(' + item.img + ')'" />
                    </v-card>
                </swiper-slide>

                <div slot="pagination" class="swiper-control" @click.prevent>
                    <swiper-button-secondary />
                    <swiper-button-secondary next />
                </div>
            </swiper>
        </div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

import SwiperPaginationPrimary from "@/components/publish/parents/swipers/swiper-pagination-primary.vue";
import SwiperBtnWrapPrimary from "@/components/publish/parents/swipers/swiper-btn-wrap-primary.vue";
import SwiperButtonSecondary from "@/components/publish/parents/swipers/swiper-button-secondary.vue";
import BtnArrowPrimary from "@/components/publish/parents/buttons/btn-arrow-primary.vue";

export default {
    components: {
        Swiper,
        SwiperSlide,
        SwiperPaginationPrimary,
        SwiperBtnWrapPrimary,
        SwiperButtonSecondary,
        BtnArrowPrimary,
    },
    props: {},
    data() {
        return {
            swiperOptionTop: {
                loop: true,
                loopedSlides: 4, // looped slides should be the same
                spaceBetween: 10,
                breakpoints: {
                    1200: {
                        spaceBetween: 24,
                    },
                },
            },
            swiperOptionThumbs: {
                loop: true,
                loopedSlides: 4, // looped slides should be the same
                spaceBetween: 10,
                centeredSlides: false,
                slidesPerView: "auto",
                touchRatio: 0.2,
                slideToClickedSlide: true,
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
                breakpoints: {
                    1200: {
                        spaceBetween: 24,
                    },
                },
            },

            items: [
                { img: "/images/sub/intro/intro-introduce-slider.jpg" },
                { img: "/images/sub/intro/intro-introduce-slider2.jpg" },
                { img: "/images/sub/intro/intro-introduce-slider3.jpg" },
                { img: "/images/sub/intro/intro-introduce-slider4.jpg" },
                { img: "/images/sub/intro/intro-introduce-slider5.jpg" },
                { img: "/images/sub/intro/intro-introduce-slider6.jpg" },
                { img: "/images/sub/intro/intro-introduce-slider7.jpg" },
                { img: "/images/sub/intro/intro-introduce-slider8.jpg" },
            ]
            ,
        };
    },
    mounted() {
        this.$nextTick(() => {
            const swiperTop = this.$refs.swiperTop.$swiper;
            const swiperThumbs = this.$refs.swiperThumbs.$swiper;
            swiperTop.controller.control = swiperThumbs;
            swiperThumbs.controller.control = swiperTop;
        });
    },
    methods: {},
};
</script>

<style lang="scss" scoped>
// thumbs-slider
.thumbs-slider {
    position: relative;
    width: 100%;
    &__img {
        width: 100%;
        padding-top: calc(800 / 1200 * 100%);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
}
.thumbs-slider-thumbs {
    margin: 10px auto 0;
    padding: 0 var(--container-gutter);

    .swiper-container,
    .swiper-control {
        max-width: calc(var(--container) + ((var(--container-gutter) + 60px) * 2));
    }
    .swiper-container {
        &::before,
        &::after {
            content: "";
            position: absolute;
            top: 0;
            z-index: 2;
            display: block;
            width: calc(var(--container-gutter) + 60px);
            height: 100%;
            background-color: #fff;
        }
        &::before {
            left: 0;
        }
        &::after {
            right: 0;
        }
    }
    .swiper-control {
        z-index: 3;
        height: auto;
    }
    .swiper-container::before,
    .swiper-container::after,
    .swiper-control {
        display: none;
    }
    .swiper-slide {
        width: calc(25% - 7.5px);
        cursor: pointer;
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            display: block;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.3);
            border-bottom: 4px solid var(--v-primary-base);
            -webkit-transition: all ease-out 0.5s;
            -ms-transition: all ease-out 0.5s;
            transition: all ease-out 0.5s;
            opacity: 0;
            visibility: hidden;
        }
        &-active {
            cursor: default;
            &::before {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
    .thumbs-slider-thumbs {
        margin-top: 24px;
        padding: 0;

        .swiper-container {
            padding: 0 calc(var(--container-gutter) + 60px);
        }
        .swiper-container::before,
        .swiper-container::after,
        .swiper-control {
            display: block;
        }
        .swiper-slide {
            width: calc(25% - 18px);
        }
    }
}
</style>

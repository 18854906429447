<template>
    <app-primary>
        <template #contentsHead>
            <sub-head-primary itemTitle="공용작업실" tabActive="답십리 신청하기" bg="/images/sub/sub-visual5.jpg">
                <template #tit>공용작업실</template>
            </sub-head-primary>
        </template>

        <page-section-primary titWrapClass="text-center" class="page-section--first page-section--last">
            <template #tit> 답십리 신청하기 </template>

            <template #pageSectionFoot>
                <page-section-secondary class="page-section--xs py-0">
                    <template #tit> 기업회원 신청 </template>

                    <card-primary cardInnerSize="card-inner--sm" class="border-4">
                        <txt-primary txtSize="txt--lg" class="d-flex align-center flex-wrap"
                            >기업회원은
                            <div class="d-inline-flex align-center flex-wrap mr-4px"><i class="icon icon-phone mx-4px" /><b class="primary--text">02-2127-5605</b>로</div>
                            문의하여 신청바랍니다.</txt-primary
                        >
                    </card-primary>
                </page-section-secondary>

                <page-section-secondary class="page-section--xs pb-0">
                    <template #tit> 개인회원 신청(답십리) </template>

                    <div class="pb-10px pb-md-16px">
                        <v-row align="center">
                            <v-col>
                                <tit-primary titSize="tit--sm">신청 내역</tit-primary>
                            </v-col>
                            <v-col cols="auto">
                                <btn-primary large to="/studio/apply2" class="v-btn--width-fixed">신청하기</btn-primary>
                            </v-col>
                        </v-row>
                    </div>

                    <table-secondary data-aos="fade" class="table-fixed">
                        <template v-slot:header>
                            <thead>
                                <tr>
                                    <th-primary style="width: 25%">신청일자</th-primary>
                                    <th-primary style="width: 25%">신청시간</th-primary>
                                    <th-primary style="width: 25%">신청자</th-primary>
                                    <th-primary style="width: 25%">신청장비</th-primary>
                                    <th-primary style="width: 25%">승인여부</th-primary>
                                    <th-primary style="width: 25%">승인일자</th-primary>
                                </tr>
                            </thead>
                        </template>
                        <template v-slot:body>
                            <tbody>
                                <tr v-for="(application, index) in applications" :key="index" class="cursor-pointer">
                                    <td class="text-center">{{$dayjs(application.applyDate).format("YYYY-MM-DD")}}</td>
                                    <td class="text-center">{{application.applyTime}}</td>
                                    <td class="text-center">{{application.name}}</td>
                                    <td class="text-center">{{application.equipments.join(",")}}</td>
                                    <td class="text-center">
                                        <chip-primary4 v-if="application.status === APPLICATION_STATUS.REJECTED.value" small>{{ APPLICATION_STATUS.REJECTED.text }}</chip-primary4>
                                        <chip-primary5 v-if="application.status === APPLICATION_STATUS.APPROVED.value" small>{{ APPLICATION_STATUS.APPROVED.text }}</chip-primary5>
                                        <chip-primary6 v-if="application.status === APPLICATION_STATUS.WAITING.value" small>{{ APPLICATION_STATUS.WAITING.text }}</chip-primary6>
                                    </td>
                                    <td class="text-center">{{ application.approvedAt ? $dayjs(application.approvedAt).format("YYYY-MM-DD") : "-"}}</td>
                                </tr>
                            </tbody>
                        </template>
                    </table-secondary>

                    <div class="pagination-wrap"><pagination-primary v-model="page" :length="pageCount" :total-visible="11" @input="search()"/></div>
                </page-section-secondary>
            </template>
        </page-section-primary>
    </app-primary>
</template>

<script>
import api from "@/api";
import { APPLICATION_STATUS } from "@/assets/variables";
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import PageSectionSecondary from "@/sets/parents/pages/page-section-secondary.vue";
import BtnPrimary from "@/components/publish/parents/buttons/btn-primary.vue";
import CardPrimary from "@/components/publish/parents/cards/card-primary.vue";
import TitPrimary from "@/components/publish/parents/typography/tit-primary.vue";
import TxtPrimary from "@/components/publish/parents/typography/txt-primary.vue";
import TableSecondary from "@/components/publish/parents/tables/table-secondary.vue";
import ThPrimary from "@/components/publish/parents/tables/th-primary.vue";
import TdPrimary from "@/components/publish/parents/tables/td-primary.vue";
import PaginationPrimary from "@/components/publish/parents/paginations/pagination-primary.vue";
import ChipPrimary4 from "@/components/publish/parents/chips/chip-primary4.vue";
import ChipPrimary5 from "@/components/publish/parents/chips/chip-primary5.vue";
import ChipPrimary6 from "@/components/publish/parents/chips/chip-primary6.vue";

export default {
    props: {},
    components: {
        AppPrimary,
        SubHeadPrimary,
        PageSectionPrimary,
        PageSectionSecondary,
        BtnPrimary,
        CardPrimary,
        TitPrimary,
        TxtPrimary,
        TableSecondary,
        ThPrimary,
        TdPrimary,
        PaginationPrimary,
        ChipPrimary4,
        ChipPrimary5,
        ChipPrimary6,
    },
    data() {
        return {
            APPLICATION_STATUS,
            limit: 10,
            page: 1,
            filter: {},
            pageCount: 0,

            summary: {},
            applications: []
        };
    },
    mounted() {
        this.search();
    },
    methods: {
        temp(){
            alert("현재 점검 중입니다. 잠시 뒤에 시도해주세요.");
        },
        async search() {
            try {
                let { summary, applications } = await api.v1.apply.applications.gets({
                    headers: { skip: (this.page - 1) * this.limit, limit: this.limit },
                    params: this.filter
                })
                this.applications= applications;
                this.pageCount= Math.ceil(summary.totalCount / this.limit);
            } catch (error) {
                console.error(error.message);
                alert(error.response ? error.response.data.message : error.message);
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.table-secondary {
    > table {
        min-width: 320px;
    }
}
</style>

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-primary', {
    scopedSlots: _vm._u([{
      key: "contentsHead",
      fn: function () {
        return [_c('sub-head-primary', {
          attrs: {
            "itemTitle": "공용작업실",
            "tabActive": "답십리 신청하기",
            "bg": "/images/sub/sub-visual5.jpg"
          },
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v("공용작업실")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('page-section-primary', {
    staticClass: "page-section--first page-section--last",
    attrs: {
      "titWrapClass": "text-center"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v(" 답십리 신청하기 ")];
      },
      proxy: true
    }, {
      key: "pageSectionFoot",
      fn: function () {
        return [_c('page-section-secondary', {
          staticClass: "page-section--xs py-0",
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v(" 기업회원 신청 ")];
            },
            proxy: true
          }])
        }, [_c('card-primary', {
          staticClass: "border-4",
          attrs: {
            "cardInnerSize": "card-inner--sm"
          }
        }, [_c('txt-primary', {
          staticClass: "d-flex align-center flex-wrap",
          attrs: {
            "txtSize": "txt--lg"
          }
        }, [_vm._v("기업회원은 "), _c('div', {
          staticClass: "d-inline-flex align-center flex-wrap mr-4px"
        }, [_c('i', {
          staticClass: "icon icon-phone mx-4px"
        }), _c('b', {
          staticClass: "primary--text"
        }, [_vm._v("02-2127-5605")]), _vm._v("로")]), _vm._v(" 문의하여 신청바랍니다.")])], 1)], 1), _c('page-section-secondary', {
          staticClass: "page-section--xs pb-0",
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v(" 개인회원 신청(답십리) ")];
            },
            proxy: true
          }])
        }, [_c('div', {
          staticClass: "pb-10px pb-md-16px"
        }, [_c('v-row', {
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', [_c('tit-primary', {
          attrs: {
            "titSize": "tit--sm"
          }
        }, [_vm._v("신청 내역")])], 1), _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('btn-primary', {
          staticClass: "v-btn--width-fixed",
          attrs: {
            "large": "",
            "to": "/studio/apply2"
          }
        }, [_vm._v("신청하기")])], 1)], 1)], 1), _c('table-secondary', {
          staticClass: "table-fixed",
          attrs: {
            "data-aos": "fade"
          },
          scopedSlots: _vm._u([{
            key: "header",
            fn: function () {
              return [_c('thead', [_c('tr', [_c('th-primary', {
                staticStyle: {
                  "width": "25%"
                }
              }, [_vm._v("신청일자")]), _c('th-primary', {
                staticStyle: {
                  "width": "25%"
                }
              }, [_vm._v("신청시간")]), _c('th-primary', {
                staticStyle: {
                  "width": "25%"
                }
              }, [_vm._v("신청자")]), _c('th-primary', {
                staticStyle: {
                  "width": "25%"
                }
              }, [_vm._v("신청장비")]), _c('th-primary', {
                staticStyle: {
                  "width": "25%"
                }
              }, [_vm._v("승인여부")]), _c('th-primary', {
                staticStyle: {
                  "width": "25%"
                }
              }, [_vm._v("승인일자")])], 1)])];
            },
            proxy: true
          }, {
            key: "body",
            fn: function () {
              return [_c('tbody', _vm._l(_vm.applications, function (application, index) {
                return _c('tr', {
                  key: index,
                  staticClass: "cursor-pointer"
                }, [_c('td', {
                  staticClass: "text-center"
                }, [_vm._v(_vm._s(_vm.$dayjs(application.applyDate).format("YYYY-MM-DD")))]), _c('td', {
                  staticClass: "text-center"
                }, [_vm._v(_vm._s(application.applyTime))]), _c('td', {
                  staticClass: "text-center"
                }, [_vm._v(_vm._s(application.name))]), _c('td', {
                  staticClass: "text-center"
                }, [_vm._v(_vm._s(application.equipments.join(",")))]), _c('td', {
                  staticClass: "text-center"
                }, [application.status === _vm.APPLICATION_STATUS.REJECTED.value ? _c('chip-primary4', {
                  attrs: {
                    "small": ""
                  }
                }, [_vm._v(_vm._s(_vm.APPLICATION_STATUS.REJECTED.text))]) : _vm._e(), application.status === _vm.APPLICATION_STATUS.APPROVED.value ? _c('chip-primary5', {
                  attrs: {
                    "small": ""
                  }
                }, [_vm._v(_vm._s(_vm.APPLICATION_STATUS.APPROVED.text))]) : _vm._e(), application.status === _vm.APPLICATION_STATUS.WAITING.value ? _c('chip-primary6', {
                  attrs: {
                    "small": ""
                  }
                }, [_vm._v(_vm._s(_vm.APPLICATION_STATUS.WAITING.text))]) : _vm._e()], 1), _c('td', {
                  staticClass: "text-center"
                }, [_vm._v(_vm._s(application.approvedAt ? _vm.$dayjs(application.approvedAt).format("YYYY-MM-DD") : "-"))])]);
              }), 0)];
            },
            proxy: true
          }])
        }), _c('div', {
          staticClass: "pagination-wrap"
        }, [_c('pagination-primary', {
          attrs: {
            "length": _vm.pageCount,
            "total-visible": 11
          },
          on: {
            "input": function ($event) {
              return _vm.search();
            }
          },
          model: {
            value: _vm.page,
            callback: function ($$v) {
              _vm.page = $$v;
            },
            expression: "page"
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
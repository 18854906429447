<template>
    <app-primary>
        <template #contentsHead>
            <sub-head-primary itemTitle="공용재단실" tabActive="스케쥴" bg="/images/sub/sub-visual4.jpg">
                <template #tit>공용재단실</template>
            </sub-head-primary>
        </template>

        <page-section-primary titWrapClass="text-center" class="page-section--first page-section--last">
            <template #tit>스케쥴</template>

            <v-divider class="grey darken4" style="border-width: 2px 0 0" />
            <div class="pa-12px px-md-20px py-md-16px">
                <tit-primary titSize="tit--sm">
                    {{ $dayjs(this.$route.query.date).format("YYYY-MM-DD") }}
                </tit-primary>
            </div>

            <table-tertiary>
                <template v-slot:header>
                    <thead>
                        <tr>
                            <th-primary style="width: 34%">업체명</th-primary>
                            <th-primary style="width: 34%">사용기기</th-primary>
                            <th-primary style="width: 32%">수량</th-primary>
                        </tr>
                    </thead>
                </template>
                <template v-slot:body>
                    <tbody>
                        <tr v-for="(schedule, index) in schedules" :key="index">
                            <td class="text-center">{{ schedule.company }}</td>
                            <td class="text-center">{{ schedule.equipment }}</td>
                            <td class="text-center">{{ schedule.quantity}}</td>
                        </tr>
                    </tbody>
                </template>
            </table-tertiary>

            <div class="btn-wrap btn-wrap--sm">
                <v-row align="center" justify="end">
                    <v-col cols="auto">
                        <btn-secondary2 @click="$router.go(-1)">리스트</btn-secondary2>
                    </v-col>
                </v-row>
            </div>
        </page-section-primary>
    </app-primary>
</template>

<script>
import api from "@/api";
import { DDM_WORK_TYPES } from '@/assets/variables';
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import TableTertiary from "@/components/publish/parents/tables/table-tertiary.vue";
import ThPrimary from "@/components/publish/parents/tables/th-primary.vue";
import TdPrimary from "@/components/publish/parents/tables/td-primary.vue";
import BtnSecondary2 from "@/components/publish/parents/buttons/btn-secondary2.vue";
import TitPrimary from "@/components/publish/parents/typography/tit-primary.vue";

export default {
    components: {
        AppPrimary,
        SubHeadPrimary,
        PageSectionPrimary,
        TableTertiary,
        ThPrimary,
        TdPrimary,
        BtnSecondary2,
        TitPrimary,
    },
    data(){
        return {
            filter:{
                scheduleDate: this.$route.query.date,
                code: DDM_WORK_TYPES.CUTTINGROOM.value
            },
            schedules: []
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        async init(){
            this.search();
        },
        async search(){
            try{
                let { summary, schedules } = await api.v1.schedule.gets({
                    params: this.filter
                })

                this.schedules = schedules;
            } catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        }
    },
};
</script>

<style lang="scss" scoped></style>

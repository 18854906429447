<template>
    <app-primary>
        <template #contentsHead>
            <sub-head-primary itemTitle="센터소개" tabActive="오시는 길" bg="/images/sub/sub-visual.jpg">
                <template #tit>센터소개</template>
            </sub-head-primary>
        </template>

        <page-section-primary titWrapClass="text-center" class="page-section--first page-section--last">
            <template #tit> 오시는 길 </template>

            <v-row class="row--xl">
                <v-col cols="12">
                    <card-primary>
                        <v-row no-gutters>
                            <v-col cols="12" lg="6">
                                <v-responsive :aspect-ratio="$vuetify.breakpoint.lgAndUp ? 600 / 452 : 4 / 2">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3161.998962402453!2d127.02708837621502!3d37.57864212343333!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357cbcad8c79bb6d%3A0x29359110c189de39!2z7ISc7Jq47Yq567OE7IucIOuPmeuMgOusuOq1rCDtlZzruZvroZwgNjI!5e0!3m2!1sko!2skr!4v1702625173350!5m2!1sko!2skr" width="100%" height="100%" style="border: 0" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                </v-responsive>
                            </v-col>
                            <v-col cols="12" lg="6">
                                <div class="pa-20px pa-md-40px pa-lg-60px">
                                    <v-row>
                                        <v-col cols="12"><tit-primary>용신동 패션봉제 복합지원센터</tit-primary></v-col>
                                        <v-col cols="12">
                                            <v-divider class="primary" style="border-width: 2px 0 0" />
                                        </v-col>
                                        <v-col cols="12">
                                            <u-txt-items itemsSize>
                                                <v-col cols="12">
                                                    <u-txt-items txtItem size="lg">
                                                        <template #tit>
                                                            <v-row class="row--xs">
                                                                <v-col cols="auto">
                                                                    <i class="icon icon-place" />
                                                                </v-col>
                                                                <v-col> Address </v-col>
                                                            </v-row>
                                                        </template>
                                                        <template #txt>동대문구 한빛로 62, 3층~7층</template>
                                                    </u-txt-items>
                                                </v-col>
                                                <v-col cols="12">
                                                    <u-txt-items txtItem size="lg">
                                                        <template #tit>
                                                            <v-row class="row--xs">
                                                                <v-col cols="auto">
                                                                    <i class="icon icon-tel" />
                                                                </v-col>
                                                                <v-col> Tel </v-col>
                                                            </v-row>
                                                        </template>
                                                        <template #txt>02-6235-2200~1</template>
                                                    </u-txt-items>
                                                </v-col>
                                                <v-col cols="12">
                                                    <u-txt-items txtItem size="lg">
                                                        <template #tit>
                                                            <v-row class="row--xs">
                                                                <v-col cols="auto">
                                                                    <i class="icon icon-fax" />
                                                                </v-col>
                                                                <v-col> Fax </v-col>
                                                            </v-row>
                                                        </template>
                                                        <template #txt>02-3299-2622</template>
                                                    </u-txt-items>
                                                </v-col>
                                                <v-col cols="12">
                                                    <u-txt-items txtItem size="lg">
                                                        <template #tit>
                                                            <v-row class="row--xs">
                                                                <v-col cols="auto">
                                                                    <i class="icon icon-car" />
                                                                </v-col>
                                                                <v-col> Parking </v-col>
                                                            </v-row>
                                                        </template>
                                                        <template #txt>지정석 주차장이므로 주차가 안되오니 가급적 대중교통을 이용하여 주시기 바랍니다.</template>
                                                    </u-txt-items>
                                                </v-col>
                                            </u-txt-items>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-col>
                        </v-row>
                    </card-primary>
                </v-col>
                <v-col cols="12">
                    <card-primary>
                        <v-row no-gutters align="center">
                            <v-col cols="12" lg="6">
                                <v-responsive :aspect-ratio="$vuetify.breakpoint.lgAndUp ? 600 / 452 : 4 / 2">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3162.217706522144!2d127.04764891177788!3d37.573490923612134!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357cbb1f83753bd5%3A0x13b72025fdc136af!2z64-Z64yA66y46rWsIOywveyXheyngOybkOyEvO2EsA!5e0!3m2!1sko!2skr!4v1702625093453!5m2!1sko!2skr" width="100%" height="100%" style="border: 0" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                </v-responsive>
                            </v-col>
                            <v-col cols="12" lg="6">
                                <div class="pa-20px pa-md-40px pa-lg-60px">
                                    <v-row>
                                        <v-col cols="12"><tit-primary>답십리 패션봉제지원센터</tit-primary></v-col>
                                        <v-col cols="12">
                                            <v-divider class="primary" style="border-width: 2px 0 0" />
                                        </v-col>
                                        <v-col cols="12">
                                            <u-txt-items itemsSize>
                                                <v-col cols="12">
                                                    <u-txt-items txtItem size="lg">
                                                        <template #tit>
                                                            <v-row class="row--xs">
                                                                <v-col cols="auto">
                                                                    <i class="icon icon-place" />
                                                                </v-col>
                                                                <v-col> Address </v-col>
                                                            </v-row>
                                                        </template>
                                                        <template #txt>동대문구 답십리로 38길 19, <br class="d-none d-md-block" />창업지원센터 A동·B동 1층</template>
                                                    </u-txt-items>
                                                </v-col>
                                                <v-col cols="12">
                                                    <u-txt-items txtItem size="lg">
                                                        <template #tit>
                                                            <v-row class="row--xs">
                                                                <v-col cols="auto">
                                                                    <i class="icon icon-tel" />
                                                                </v-col>
                                                                <v-col> Tel </v-col>
                                                            </v-row>
                                                        </template>
                                                        <template #txt>02-2212-5586</template>
                                                    </u-txt-items>
                                                </v-col>
                                                <v-col cols="12">
                                                    <u-txt-items txtItem size="lg">
                                                        <template #tit>
                                                            <v-row class="row--xs">
                                                                <v-col cols="auto">
                                                                    <i class="icon icon-fax" />
                                                                </v-col>
                                                                <v-col> Fax </v-col>
                                                            </v-row>
                                                        </template>
                                                        <template #txt>02-3299-2622</template>
                                                    </u-txt-items>
                                                </v-col>
                                                <v-col cols="12">
                                                    <u-txt-items txtItem size="lg">
                                                        <template #tit>
                                                            <v-row class="row--xs">
                                                                <v-col cols="auto">
                                                                    <i class="icon icon-car" />
                                                                </v-col>
                                                                <v-col> Parking </v-col>
                                                            </v-row>
                                                        </template>
                                                        <template #txt>지정석 주차장이므로 주차가 안되오니 가급적 대중교통을 이용하여 주시기 바랍니다.</template>
                                                    </u-txt-items>
                                                </v-col>
                                            </u-txt-items>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-col>
                        </v-row>
                    </card-primary>
                </v-col>
            </v-row>
        </page-section-primary>
    </app-primary>
</template>

<script>
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import BtnPrimary from "@/components/publish/parents/buttons/btn-primary.vue";
import CardPrimary from "@/components/publish/parents/cards/card-primary.vue";
import TitPrimary from "@/components/publish/parents/typography/tit-primary.vue";

import UTxtItems from "@/components/publish/styles/items/u-txt-items.vue";

export default {
    props: {},
    components: {
        AppPrimary,
        SubHeadPrimary,
        PageSectionPrimary,
        BtnPrimary,
        CardPrimary,
        TitPrimary,

        UTxtItems,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    ::v-deep {
        .txt-item {
            margin: 0;
            &__col-tit,
            &__col-txt {
                padding: 0;
            }
            &__col-tit {
                width: 140px;
            }
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
</style>

<template>
    <app-primary>
        <template #contentsHead>
            <sub-head-primary itemTitle="센터소개" tabActive="소개" bg="/images/sub/sub-visual.jpg">
                <template #tit>센터소개</template>
            </sub-head-primary>
        </template>

        <page-section-primary titWrapClass="text-center" class="page-section--first page-section--sm">
            <template #tit> 소개 </template>

            <v-row align="center" class="row--xl">
                <v-col cols="12" lg="auto">
                    <v-img :width="$vuetify.breakpoint.lgAndUp ? 486 : '100%'" :aspect-ratio="$vuetify.breakpoint.lgAndUp ? 486 / 572 : 6 / 2" :position="$vuetify.breakpoint.lgAndUp ? 'center center' : '50% 20%'" src="/images/sub/intro/intro-introduce-img.jpg" />
                </v-col>
                <v-col cols="12" lg="">
                    <v-row>
                        <v-col cols="12">
                            <tit-primary class="font-weight-regular">동대문구 <b class="primary--text">패션 봉제지원센터</b></tit-primary>
                        </v-col>
                        <v-col cols="12">
                            <txt-primary class="txt--lg txt--dark line-height-15"> 동대문구 제조업의 약 40%를 차지하는 패션봉제산업을 지원하기 위하여 <br class="d-none d-md-block" />동대문구에 2개의 패션봉제지원센터를 운영하고 있습니다. </txt-primary>
                        </v-col>
                        <v-col cols="12">
                            <txt-primary class="txt--lg txt--dark line-height-15"> 스마트 제조장비, 공동작업장 운영, 일감연계, 판로개척, 소공인 협업강화, 봉제인력 양성교육 등 다각적인 지원을 통해 패션봉제산업 활성화에 기여하고자 합니다. </txt-primary>
                        </v-col>
                        <v-col cols="12">
                            <card-primary cardInnerSize="card-inner--sm" class="border-4">
                                <u-txt-items>
                                    <v-col cols="12">
                                        <u-txt-items txtItem size="lg">
                                            <template #tit>운영시간</template>
                                            <template #txt>매주 월 ~ 금요일 오전 9시 ~ 오후 6시</template>
                                        </u-txt-items>
                                    </v-col>
                                    <v-col cols="12">
                                        <u-txt-items txtItem size="lg">
                                            <template #tit>주 소</template>
                                            <template #txt>동대문구 한빛로 62<br />동대문구 답십리로 38길 19, 1층</template>
                                        </u-txt-items>
                                    </v-col>
                                    <v-col cols="12">
                                        <u-txt-items txtItem size="lg">
                                            <template #tit>휴 관 일</template>
                                            <template #txt>주말 및 공휴일</template>
                                        </u-txt-items>
                                    </v-col>
                                    <v-col cols="12">
                                        <u-txt-items txtItem size="lg">
                                            <template #tit>주 차 장</template>
                                            <template #txt>주차장이 협소 하오니 가급적 대중교통을 이용하여 주시기 바랍니다.</template>
                                        </u-txt-items>
                                    </v-col>
                                </u-txt-items>
                            </card-primary>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </page-section-primary>

        <page-section-primary titWrapClass="text-center" class="page-section--sm pt-0">
            <template #pageSectionFoot>
                <thumbs-slider />
            </template>
        </page-section-primary>

        <page-section-primary titWrapClass="text-center" class="page-section--last page-section--sm pt-0">
            <table-primary>
                <template v-slot:header>
                    <thead>
                        <tr>
                            <th-primary colspan="2" style="width: 50%">용신동 패션봉제 복합지원센터</th-primary>
                            <th-primary colspan="2" style="width: 50%">답십리 패션봉제지원센터</th-primary>
                        </tr>
                    </thead>
                </template>
                <template v-slot:body>
                    <tbody>
                        <tr>
                            <td-primary class="grey lighten-5" style="width: 20%">위치(면적)</td-primary>
                            <td-primary class="grey lighten-5">용도</td-primary>
                            <td-primary class="grey lighten-5" style="width: 20%">위치(면적)</td-primary>
                            <td-primary class="grey lighten-5">용도</td-primary>
                        </tr>
                        <tr>
                            <td-primary>3층(145.59㎡)</td-primary>
                            <td-primary>사무실,전시실,창고</td-primary>
                            <td-primary>A동 1층 –1 (37.22㎡)</td-primary>
                            <td-primary>사무실</td-primary>
                        </tr>
                        <tr>
                            <td-primary>4층(145.59㎡)</td-primary>
                            <td-primary>공용재단실</td-primary>
                            <td-primary>A동 1층 –2 (82.54㎡)</td-primary>
                            <td-primary>교육실</td-primary>
                        </tr>
                        <tr>
                            <td-primary>5층(145.59㎡)</td-primary>
                            <td-primary>공용장비실1</td-primary>
                            <td-primary>A동 1층 –3 (69.35㎡)</td-primary>
                            <td-primary>공용작업실,전시실</td-primary>
                        </tr>
                        <tr>
                            <td-primary>6층(145.59㎡)</td-primary>
                            <td-primary>공용장비실2</td-primary>
                            <td-primary>A동 지하1층 (52.36㎡)</td-primary>
                            <td-primary>창고</td-primary>
                        </tr>
                        <tr>
                            <td-primary>7층(122.49㎡)</td-primary>
                            <td-primary>교육실,커뮤니티실</td-primary>
                            <td-primary>B동 1층 (176.8㎡)</td-primary>
                            <td-primary>공용재단실</td-primary>
                        </tr>
                    </tbody>
                </template>
            </table-primary>

            <table></table>
        </page-section-primary>
    </app-primary>
</template>

<script>
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import BtnPrimary from "@/components/publish/parents/buttons/btn-primary.vue";
import TitPrimary from "@/components/publish/parents/typography/tit-primary.vue";
import TxtPrimary from "@/components/publish/parents/typography/txt-primary.vue";
import CardPrimary from "@/components/publish/parents/cards/card-primary.vue";
import TablePrimary from "@/components/publish/parents/tables/table-primary.vue";
import ThPrimary from "@/components/publish/parents/tables/th-primary.vue";
import TdPrimary from "@/components/publish/parents/tables/td-primary.vue";

import UTxtItems from "@/components/publish/styles/items/u-txt-items.vue";
import ThumbsSlider from "@/components/publish/styles/slider/thumbs-slider.vue";

export default {
    props: {},
    components: {
        AppPrimary,
        SubHeadPrimary,
        PageSectionPrimary,
        BtnPrimary,
        TitPrimary,
        TxtPrimary,
        CardPrimary,
        TablePrimary,
        ThPrimary,
        TdPrimary,

        UTxtItems,
        ThumbsSlider,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>

<template>
    <app-primary>
        <template #contentsHead>
            <sub-head-primary itemTitle="공용작업실" tabActive="신청방법" bg="/images/sub/sub-visual5.jpg">
                <template #tit>공용작업실</template>
            </sub-head-primary>
        </template>

        <page-section-secondary class="page-section--first page-section--xs">
            <template #tit>운영안내</template>

            <card-primary cardInnerSize="card-inner--sm" class="border-4">
                <u-txt-items itemsSize="row--xs">
                    <v-col cols="12">
                        <u-txt-items txtItem itemSize="row--sm" titCols="auto" txtCols="" titWidth="min-width:98px;" titClass="txt--lg line-height-15" txtClass="txt--lg line-height-15">
                            <template #tit>이용시간</template>
                            <template #txt>
                                평일 09:00 ~ 16:30 (주말,공휴일 휴무)<br />
                                <div class="primary--text">※ 직원 휴가 시 공용작업실 운영 휴무 (최소 1일 전 문자 안내)</div>
                            </template>
                        </u-txt-items>
                    </v-col>
                    <v-col cols="12">
                        <u-txt-items txtItem itemSize="row--sm" titCols="auto" txtCols="" titWidth="min-width:98px;" titClass="txt--lg line-height-15" txtClass="txt--lg line-height-15">
                            <template #tit>장소</template>
                            <template #txt>
                                동대문구 답십리로38길 19 A동 1층(답십리동)<br />
                                <div class="primary--text">※ 용신동 패션봉제 복합지원센터는 공용작업실을 운영하지 않습니다.</div>
                            </template>
                        </u-txt-items>
                    </v-col>
                    <v-col cols="12">
                        <u-txt-items txtItem itemSize="row--sm" titCols="auto" txtCols="" titWidth="min-width:98px;" titClass="txt--lg line-height-15" txtClass="txt--lg line-height-15">
                            <template #tit>문의처</template>
                            <template #txt>02-2212-5586</template>
                        </u-txt-items>
                    </v-col>
                    <v-col cols="12">
                        <u-txt-items txtItem itemSize="row--sm" titCols="auto" txtCols="" titWidth="min-width:98px;" titClass="txt--lg line-height-15" txtClass="txt--lg line-height-15">
                            <template #tit>이용가능 장비</template>
                            <template #txt>본봉(3대), 오버록(1대), 날날이(1대), 캐드, 마네킹</template>
                        </u-txt-items>
                    </v-col>
                </u-txt-items>
            </card-primary>
        </page-section-secondary>

        <page-section-secondary class="page-section--xs pt-0">
            <template #tit>주의사항</template>

            <card-primary cardInnerSize="card-inner--sm" class="border-4">
                <u-txt-items itemsSize="row--xs">
                    <v-col cols="12">
                        <u-txt-items txtItem titCols="auto" txtCols="" class="ma-n2px" colClass="pa-2px" titWidth="" titClass="txt--lg line-height-15" txtClass="txt--lg line-height-15">
                            <template #tit>·</template>
                            <template #txt>
                                공용작업실은 <b>사전예약제</b>로 운영되며, <b>관리자 승인 후 이용 가능</b>합니다.
                                <div class="primary--text">※ 예약 마감(금요일 13:00) 이후 공석에 한하여 전화예약 가능</div>
                            </template>
                        </u-txt-items>
                    </v-col>
                    <v-col cols="12">
                        <u-txt-items txtItem titCols="auto" txtCols="" class="ma-n2px" colClass="pa-2px" titWidth="" titClass="txt--lg line-height-15" txtClass="txt--lg line-height-15">
                            <template #tit>·</template>
                            <template #txt>1인당 <b>주 최대 3회, 1일 최대 4시간</b> 이용 가능합니다.</template>
                        </u-txt-items>
                    </v-col>
                    <v-col cols="12">
                        <u-txt-items txtItem titCols="auto" txtCols="" class="ma-n2px" colClass="pa-2px" titWidth="" titClass="txt--lg line-height-15" txtClass="txt--lg line-height-15">
                            <template #tit>·</template>
                            <template #txt><b>본봉은 1인당 1대만 예약 가능</b>하며, 본인 외 <b>대리신청은 불가</b>합니다.</template>
                        </u-txt-items>
                    </v-col>
                    <v-col cols="12">
                        <u-txt-items txtItem titCols="auto" txtCols="" class="ma-n2px" colClass="pa-2px" titWidth="" titClass="txt--lg line-height-15" txtClass="txt--lg line-height-15">
                            <template #tit>·</template>
                            <template #txt>공용작업실 내 <b>음식물 반입과 개인 물품 보관은 금지</b>됩니다.</template>
                        </u-txt-items>
                    </v-col>
                    <v-col cols="12">
                        <u-txt-items txtItem titCols="auto" txtCols="" class="ma-n2px" colClass="pa-2px" titWidth="" titClass="txt--lg line-height-15" txtClass="txt--lg line-height-15">
                            <template #tit>·</template>
                            <template #txt>퇴실 전 <b>본인이 이용한 자리를 정리</b>해주시고, <b>다리미, 냉․난방기, 전등의 전원</b>을 꺼주시기 바랍니다.</template>
                        </u-txt-items>
                    </v-col>
                    <v-col cols="12">
                        <u-txt-items txtItem titCols="auto" txtCols="" class="ma-n2px" colClass="pa-2px" titWidth="" titClass="txt--lg line-height-15" txtClass="txt--lg line-height-15">
                            <template #tit>·</template>
                            <template #txt>개인의 부주의로 인한 사고, 부상, 장비파손, 물품 분실 등에 대해서는 센터가 책임지지 않으며 시설물 훼손․파손 등에 대해서는 사용자가 배상합니다.</template>
                        </u-txt-items>
                    </v-col>
                    <v-col cols="12">
                        <u-txt-items txtItem titCols="auto" txtCols="" class="ma-n2px" colClass="pa-2px" titWidth="" titClass="txt--lg line-height-15" txtClass="txt--lg line-height-15">
                            <template #tit>·</template>
                            <template #txt>주의사항을 준수하지 않을 경우 공용작업실 이용이 제한될 수 있습니다.</template>
                        </u-txt-items>
                    </v-col>
                </u-txt-items>
            </card-primary>
        </page-section-secondary>

        <page-section-secondary class="page-section--xs pt-0">
            <template #tit>예약안내</template>

            <card-primary cardInnerSize="card-inner--sm" class="border-4">
                <u-txt-items itemsSize="row--xs">
                    <v-col cols="12">
                        <u-txt-items txtItem itemSize="row--sm" titCols="auto" txtCols="" titWidth="min-width:98px;" titClass="txt--lg line-height-15" txtClass="txt--lg line-height-15">
                            <template #tit>예약처</template>
                            <template #txt>동대문구 패션봉제지원센터 홈페이지(ddmsfcenter.com)</template>
                        </u-txt-items>
                    </v-col>
                    <v-col cols="12">
                        <u-txt-items txtItem itemSize="row--sm" titCols="auto" txtCols="" titWidth="min-width:98px;" titClass="txt--lg line-height-15" txtClass="txt--lg line-height-15">
                            <template #tit>예약가능 요일</template>
                            <template #txt>
                                매주 월 ~ 금 ⇨ <b>차주 월 ~ 금</b> 예약 가능
                                <div class="mt-10px">
                                    <v-img max-width="448" :aspect-ratio="448 / 460" src="/images/sub/studio/studio-application-img.jpg" />
                                </div>
                            </template>
                        </u-txt-items>
                    </v-col>
                    <v-col cols="12">
                        <u-txt-items txtItem itemSize="row--sm" titCols="auto" txtCols="" titWidth="min-width:98px;" titClass="txt--lg line-height-15" txtClass="txt--lg line-height-15">
                            <template #tit>예약 승인</template>
                            <template #txt>
                                매주 <b>금요일 13:00</b>이후 일괄 승인
                                <div class="primary--text">
                                    ※ 동일 시간, 동일 장비 예약 건에 대하여 <b>선착순</b>으로 승인 처리<br />
                                    ※ 예약 마감(금요일 13:00) 이후 신청 건은 미승인 처리(전화예약 가능)
                                </div>
                            </template>
                        </u-txt-items>
                    </v-col>
                </u-txt-items>
            </card-primary>
        </page-section-secondary>

        <page-section-secondary class="page-section--last page-section--xs pt-0">
            <template #tit>예약방법</template>

            <card-primary cardInnerSize="card-inner--md" class="border-4">
                <u-txt-items itemsSize="row--xs">
                    <v-col cols="12">
                        <v-img max-width="1072" :aspect-ratio="1072 / 916" src="/images/sub/studio/studio-application-img2.jpg" />
                    </v-col>
                    <v-col cols="12">
                        <u-txt-items txtItem titCols="auto" txtCols="" class="ma-n2px" colClass="pa-2px" titWidth="" titClass="txt--lg line-height-15" txtClass="txt--lg line-height-15">
                            <template #tit>1.</template>
                            <template #txt>
                                동대문구 패션봉제지원센터 홈페이지(ddmsfcenter.com) > 공용작업실 > 신청하기 접속
                                <div class="primary--text">※ 회원가입 및 로그인 불필요</div>
                            </template>
                        </u-txt-items>
                    </v-col>
                    <v-col cols="12">
                        <u-txt-items txtItem titCols="auto" txtCols="" class="ma-n2px" colClass="pa-2px" titWidth="" titClass="txt--lg line-height-15" txtClass="txt--lg line-height-15">
                            <template #tit>2.</template>
                            <template #txt>신청자명 : 예약자 이름 입력</template>
                        </u-txt-items>
                    </v-col>
                    <v-col cols="12">
                        <u-txt-items txtItem titCols="auto" txtCols="" class="ma-n2px" colClass="pa-2px" titWidth="" titClass="txt--lg line-height-15" txtClass="txt--lg line-height-15">
                            <template #tit>3.</template>
                            <template #txt>연락처 : 연락가능한 휴대전화 번호 입력</template>
                        </u-txt-items>
                    </v-col>
                    <v-col cols="12">
                        <u-txt-items txtItem titCols="auto" txtCols="" class="ma-n2px" colClass="pa-2px" titWidth="" titClass="txt--lg line-height-15" txtClass="txt--lg line-height-15">
                            <template #tit>4.</template>
                            <template #txt>
                                날짜 선택 : 차주 월~금 예약 가능
                                <div class="primary--text">※ 차주 월~금 외의 날짜 예약시 미승인 처리</div>
                            </template>
                        </u-txt-items>
                    </v-col>
                    <v-col cols="12">
                        <u-txt-items txtItem titCols="auto" txtCols="" class="ma-n2px" colClass="pa-2px" titWidth="" titClass="txt--lg line-height-15" txtClass="txt--lg line-height-15">
                            <template #tit>5.</template>
                            <template #txt>
                                시간 선택 : 오전(09:00~13:00), 오후(13:00~16:30)
                                <div class="primary--text">※ 4시간 단위로만 예약가능</div>
                            </template>
                        </u-txt-items>
                    </v-col>
                    <v-col cols="12">
                        <u-txt-items txtItem titCols="auto" txtCols="" class="ma-n2px" colClass="pa-2px" titWidth="" titClass="txt--lg line-height-15" txtClass="txt--lg line-height-15">
                            <template #tit>6.</template>
                            <template #txt>
                                장비 선택 : 본봉/날날이/캐드/오버록/마네킹 중 택1
                                <div class="primary--text">
                                    ※ 본봉은 1인당 1대만 예약 가능<br />
                                    ※ 본봉 예약 시 오버록 예약 없이 사용 가능
                                </div>
                            </template>
                        </u-txt-items>
                    </v-col>
                    <v-col cols="12">
                        <u-txt-items txtItem titCols="auto" txtCols="" class="ma-n2px" colClass="pa-2px" titWidth="" titClass="txt--lg line-height-15" txtClass="txt--lg line-height-15">
                            <template #tit>7.</template>
                            <template #txt>신청 사유 : 신청사유 및 작성</template>
                        </u-txt-items>
                    </v-col>
                </u-txt-items>
            </card-primary>
        </page-section-secondary>
    </app-primary>
</template>

<script>
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import PageSectionSecondary from "@/sets/parents/pages/page-section-secondary.vue";
import BtnPrimary from "@/components/publish/parents/buttons/btn-primary.vue";
import ChipPrimary2 from "@/components/publish/parents/chips/chip-primary2.vue";
import ChipPrimary3 from "@/components/publish/parents/chips/chip-primary3.vue";
import ChipPrimary4 from "@/components/publish/parents/chips/chip-primary4.vue";
import CardPrimary from "@/components/publish/parents/cards/card-primary.vue";
import CardSecondary from "@/components/publish/parents/cards/card-secondary.vue";
import TitPrimary from "@/components/publish/parents/typography/tit-primary.vue";
import TxtPrimary from "@/components/publish/parents/typography/txt-primary.vue";

import UTxtItems from "@/components/publish/styles/items/u-txt-items.vue";

export default {
    props: {},
    components: {
        AppPrimary,
        SubHeadPrimary,
        PageSectionPrimary,
        PageSectionSecondary,
        BtnPrimary,
        ChipPrimary2,
        ChipPrimary3,
        ChipPrimary4,
        CardPrimary,
        CardSecondary,
        TitPrimary,
        TxtPrimary,

        UTxtItems,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>

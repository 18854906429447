<template>
    <app-primary>
        <template #contentsHead>
            <sub-head-primary itemTitle="공용재단실" tabActive="신청방법" bg="/images/sub/sub-visual4.jpg">
                <template #tit>공용재단실</template>
            </sub-head-primary>
        </template>

        <page-section-primary titWrapClass="text-center" class="page-section--first page-section--xs">
            <template #tit>신청방법</template>

            <card-secondary cardInnerSize="card-inner--md" class="v-card--none-active-bg cursor-default text-center">
                <tit-primary titSize="tit--sm" class="font-weight-regular line-height-15 mb-6px mb-md-24px">동대문구에 사업장이 소재한 의류제조업체 및 디자이너 등 <br class="d-none d-md-block" />자동재단 및 패턴CAD 서비스를 희망하는 <b class="primary--text">패션소공인이면 누구나</b></tit-primary>
                <txt-primary class="txt--lg line-height-15"> 동대문구 패션 봉제 산업의 다각적인 지원과 교육을 통해 기술 경쟁력 향상 및 역량강화로 지역경제 활성화에 기여하고자 합니다. </txt-primary>
            </card-secondary>
        </page-section-primary>

        <page-section-secondary class="page-section--xs pt-0">
            <template #tit>운영안내</template>

            <card-primary cardInnerSize="card-inner--sm" class="border-4">
                <u-txt-items>
                    <v-col cols="12">
                        <u-txt-items txtItem titCols="auto" txtCols="" size="lg">
                            <template #tit>이용시간</template>
                            <template #txt>평일 오전9시 ~ 오후6시 (토,일,공휴일 휴무)</template>
                        </u-txt-items>
                    </v-col>
                    <v-col cols="12">
                        <u-txt-items txtItem titCols="auto" txtCols="" size="lg">
                            <template #tit>장소</template>
                            <template #txt>
                                동대문구 답십리로38길 19, B동 1층<span class="primary--text">(답십리)</span><br />
                                동대문구 한빛로 62, 4층<span class="accent--text">(용신동)</span>
                            </template>
                        </u-txt-items>
                    </v-col>
                    <v-col cols="12">
                        <u-txt-items txtItem titCols="auto" txtCols="" size="lg">
                            <template #tit>문의처</template>
                            <template #txt>
                                02-2212-5581<span class="primary--text">(답십리)</span><br />
                                02-6235-2202<span class="accent--text">(용신동)</span>
                            </template>
                        </u-txt-items>
                    </v-col>
                </u-txt-items>
            </card-primary>
        </page-section-secondary>

        <page-section-secondary class="page-section--last page-section--xs pt-0">
            <template #tit>이용절차</template>

            <template #pageSectionFoot>
                <page-section-primary class="page-section--xxs py-0">
                    <div class="overflow-hidden">
                        <v-row class="row--xl step-border">
                            <v-col cols="12" sm="6" md="4" lg="3">
                                <card-primary cardInnerSize="card-inner--md" innerClass="px-10px" class="border-4 text-center">
                                    <chip-primary4 class="mb-10px mb-md-18px">STEP 1</chip-primary4>
                                    <tit-primary titSize="tit--sm" class="font-weight-semibold letter-spacing-n05 mb-4px">재단 스케쥴표 확인</tit-primary>
                                    <txt-primary class="txt--lg txt--light line-height-15">인터넷 홈페이지를 통해 <br class="d-none d-md-block" />재단 스케쥴표 확인</txt-primary>
                                </card-primary>
                                <card-secondary cardInnerSize="card-inner--sm" color="primary lighten-5" class="text-center mt-4px">
                                    <txt-primary class="primary--text font-weight-bold">의뢰업체</txt-primary>
                                </card-secondary>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" lg="3">
                                <card-primary cardInnerSize="card-inner--md" innerClass="px-10px" class="border-4 text-center">
                                    <chip-primary4 class="mb-10px mb-md-18px">STEP 2</chip-primary4>
                                    <tit-primary titSize="tit--sm" class="font-weight-semibold letter-spacing-n05 mb-4px">서류 제출</tit-primary>
                                    <txt-primary class="txt--lg txt--light line-height-15">재단의뢰신청서, 사업장 <br class="d-none d-md-block" />소재지 증빙 서류(최초 1회)제출</txt-primary>
                                </card-primary>
                                <card-secondary cardInnerSize="card-inner--sm" color="primary lighten-5" class="text-center mt-4px">
                                    <txt-primary class="primary--text font-weight-bold">재단실장, 의뢰업체</txt-primary>
                                </card-secondary>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" lg="3">
                                <card-primary cardInnerSize="card-inner--md" innerClass="px-10px" class="border-4 text-center">
                                    <chip-primary4 class="mb-10px mb-md-18px">STEP 3</chip-primary4>
                                    <tit-primary titSize="tit--sm" class="font-weight-semibold letter-spacing-n05 mb-4px">재단 상담</tit-primary>
                                    <txt-primary class="txt--lg txt--light line-height-15">샘플, 패턴, 작업지시서 지참</txt-primary>
                                </card-primary>
                                <card-secondary cardInnerSize="card-inner--sm" color="primary lighten-5" class="text-center mt-4px">
                                    <txt-primary class="primary--text font-weight-bold">재단실장, 의뢰업체</txt-primary>
                                </card-secondary>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" lg="3">
                                <card-primary cardInnerSize="card-inner--md" innerClass="px-10px" class="border-4 text-center">
                                    <chip-primary4 class="mb-10px mb-md-18px">STEP 4</chip-primary4>
                                    <tit-primary titSize="tit--sm" class="font-weight-semibold letter-spacing-n05 mb-4px">원단 입고</tit-primary>
                                    <txt-primary class="txt--lg txt--light line-height-15">원단 상·하차 <br class="d-none d-md-block" />의뢰업체에서 부담</txt-primary>
                                </card-primary>
                                <card-secondary cardInnerSize="card-inner--sm" color="primary lighten-5" class="text-center mt-4px">
                                    <txt-primary class="primary--text font-weight-bold">의뢰업체</txt-primary>
                                </card-secondary>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" lg="3">
                                <card-primary cardInnerSize="card-inner--md" innerClass="px-10px" class="border-4 text-center">
                                    <chip-primary4 class="mb-10px mb-md-18px">STEP 5</chip-primary4>
                                    <tit-primary titSize="tit--sm" class="font-weight-semibold letter-spacing-n05 mb-4px">신청접수 완료</tit-primary>
                                    <txt-primary class="txt--lg txt--light line-height-15">재단서비스 신청현황 <br class="d-none d-md-block" />홈페이지 게시</txt-primary>
                                </card-primary>
                                <card-secondary cardInnerSize="card-inner--sm" color="primary lighten-5" class="text-center mt-4px">
                                    <txt-primary class="primary--text font-weight-bold">재단실장</txt-primary>
                                </card-secondary>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" lg="3">
                                <card-primary cardInnerSize="card-inner--md" innerClass="px-10px" class="border-4 text-center">
                                    <chip-primary4 class="mb-10px mb-md-18px">STEP 6</chip-primary4>
                                    <tit-primary titSize="tit--sm" class="font-weight-semibold letter-spacing-n05 mb-4px">패턴, 마카 작업</tit-primary>
                                    <txt-primary class="txt--lg txt--light line-height-15">패턴입력 및 마카작업 <br class="d-none d-md-block" />(CAD 활용)</txt-primary>
                                </card-primary>
                                <card-secondary cardInnerSize="card-inner--sm" color="primary lighten-5" class="text-center mt-4px">
                                    <txt-primary class="primary--text font-weight-bold">재단부실장</txt-primary>
                                </card-secondary>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" lg="3">
                                <card-primary cardInnerSize="card-inner--md" innerClass="px-10px" class="border-4 text-center">
                                    <chip-primary4 class="mb-10px mb-md-18px">STEP 7</chip-primary4>
                                    <tit-primary titSize="tit--sm" class="font-weight-semibold letter-spacing-n05 mb-4px">재단 작업</tit-primary>
                                    <txt-primary class="txt--lg txt--light line-height-15">재단 작업 <br class="d-none d-md-block" />(CAM 활용)</txt-primary>
                                </card-primary>
                                <card-secondary cardInnerSize="card-inner--sm" color="primary lighten-5" class="text-center mt-4px">
                                    <txt-primary class="primary--text font-weight-bold">재단실장</txt-primary>
                                </card-secondary>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" lg="3">
                                <card-primary cardInnerSize="card-inner--md" innerClass="px-10px" class="border-4 text-center">
                                    <chip-primary4 class="mb-10px mb-md-18px">STEP 8</chip-primary4>
                                    <tit-primary titSize="tit--sm" class="font-weight-semibold letter-spacing-n05 mb-4px">재단물 출고</tit-primary>
                                    <txt-primary class="txt--lg txt--light line-height-15">재단물 확인, 출고</txt-primary>
                                </card-primary>
                                <card-secondary cardInnerSize="card-inner--sm" color="primary lighten-5" class="text-center mt-4px">
                                    <txt-primary class="primary--text font-weight-bold">재단실장, 의뢰업체</txt-primary>
                                </card-secondary>
                            </v-col>
                        </v-row>
                    </div>
                    <div class="mt-16px mt-md-24px">
                        <txt-primary class="txt--lg line-height-15 txt--light">
                            공용재단실 신청 및 작업 현황은 동대문구 패션봉제지원센터 홈페이지(ddmsfcenter.com) > 공용재단실 > 스케쥴 <br class="d-none d-md-block" />
                            또는 CUTZ 어플(구글 플레이스토어 검색)에서 확인하실 수 있습니다.
                        </txt-primary>
                    </div>
                </page-section-primary>

                <page-section-primary class="page-section--xxs pb-0">
                    <card-primary cardInnerSize="card-inner--md" class="border-4">
                        <v-row class="row--xs">
                            <v-col cols="12">
                                <u-txt-items itemsSize="row--xs">
                                    <v-col cols="12">
                                        <u-txt-items txtItem titCols="auto" txtCols="" class="ma-n2px" colClass="pa-2px" titWidth="" titClass="txt--lg line-height-15" txtClass="txt--lg line-height-15">
                                            <template #tit>1.</template>
                                            <template #txt>재단실 이용 신청 시, 재단의뢰 신청서와 사업장 소재지가 동대문구임을 증빙할 수 있는 서류(사업자등록증 등/최초1회)를 제출해야 합니다.</template>
                                        </u-txt-items>
                                    </v-col>
                                </u-txt-items>
                            </v-col>
                            <v-col cols="12">
                                <u-txt-items itemsSize="row--xs">
                                    <v-col cols="12">
                                        <u-txt-items txtItem titCols="auto" txtCols="" class="ma-n2px" colClass="pa-2px" titWidth="" titClass="txt--lg line-height-15" txtClass="txt--lg line-height-15">
                                            <template #tit>2.</template>
                                            <template #txt>작업지시서, 패턴, 샘플 및 원단 입고가 모두 완료된 업체부터 작업을 진행합니다.</template>
                                        </u-txt-items>
                                    </v-col>
                                </u-txt-items>
                            </v-col>
                            <v-col cols="12">
                                <u-txt-items itemsSize="row--xs">
                                    <v-col cols="12">
                                        <u-txt-items txtItem titCols="auto" txtCols="" class="ma-n2px" colClass="pa-2px" titWidth="" titClass="txt--lg line-height-15" txtClass="txt--lg line-height-15">
                                            <template #tit>3.</template>
                                            <template #txt>
                                                직기는 겉감 위주(안감, 심지 불가), 다이마루는 몸판 위주(시보리 불가)로 재단이 가능합니다.<br />
                                                <span class="primary--text">※ 비수기(1-2월, 7-8월)에는 재단실과 협의</span>
                                            </template>
                                        </u-txt-items>
                                    </v-col>
                                </u-txt-items>
                            </v-col>
                            <v-col cols="12">
                                <u-txt-items itemsSize="row--xs">
                                    <v-col cols="12">
                                        <u-txt-items txtItem titCols="auto" txtCols="" class="ma-n2px" colClass="pa-2px" titWidth="" titClass="txt--lg line-height-15" txtClass="txt--lg line-height-15">
                                            <template #tit>4.</template>
                                            <template #txt>
                                                원단은 롤(roll) 형태로 입고하여야 하며, 원단 상․하차는 의뢰업체에서 부담합니다.<br />
                                                <span class="primary--text">※ 원단 상·하차는 재단실에서 수행하지 않습니다.</span>
                                            </template>
                                        </u-txt-items>
                                    </v-col>
                                </u-txt-items>
                            </v-col>
                            <v-col cols="12">
                                <u-txt-items itemsSize="row--xs">
                                    <v-col cols="12">
                                        <u-txt-items txtItem titCols="auto" txtCols="" class="ma-n2px" colClass="pa-2px" titWidth="" titClass="txt--lg line-height-15" txtClass="txt--lg line-height-15">
                                            <template #tit>5.</template>
                                            <template #txt> 한 업체당 월 6회 이내로 재단실 이용이 가능합니다. </template>
                                        </u-txt-items>
                                    </v-col>
                                </u-txt-items>
                            </v-col>
                            <v-col cols="12">
                                <u-txt-items itemsSize="row--xs">
                                    <v-col cols="12">
                                        <u-txt-items txtItem titCols="auto" txtCols="" class="ma-n2px" colClass="pa-2px" titWidth="" titClass="txt--lg line-height-15" txtClass="txt--lg line-height-15">
                                            <template #tit>6.</template>
                                            <template #txt> 하루 1스타일당 아래 표의 기준에 따라 재단해드립니다. </template>
                                        </u-txt-items>
                                    </v-col>
                                </u-txt-items>
                            </v-col>
                            <v-col cols="12">
                                <table-primary>
                                    <template v-slot:header>
                                        <thead>
                                            <tr>
                                                <th-primary colspan="2" style="width: 35%">원단 구분</th-primary>
                                                <th-primary style="width: 20%">재단 가능량</th-primary>
                                                <th-primary style="width: 45%">비고</th-primary>
                                            </tr>
                                        </thead>
                                    </template>
                                    <template v-slot:body>
                                        <tbody>
                                            <tr>
                                                <td-primary rowspan="2" class="grey lighten-5">다이마루<br />(티셔츠 류)</td-primary>
                                                <td-primary>두꺼운</td-primary>
                                                <td-primary>500장</td-primary>
                                                <td-primary rowspan="6">
                                                    <div class="text-left py-16px px-lg-20px">
                                                        <chip-primary2>재단 불가능한 경우</chip-primary2>
                                                        <div class="mt-8px mt-md-16px">
                                                            <v-row class="ma-n2px">
                                                                <v-col cols="12" class="pa-2px"> 1.체크, 스트라이프, 커트지 등 무늬 원단(무지 개념인 경우 가능) </v-col>
                                                                <v-col cols="12" class="pa-2px"> 2.가죽, 레쟈, 코팅원단 등 바람이 통과하지 못하는 소재 원단 </v-col>
                                                                <v-col cols="12" class="pa-2px"> 3.자투리 원단, 주름 원단 등 불규칙한 소재 원단 </v-col>
                                                                <v-col cols="12" class="pa-2px"> 4.배색이 1가지 이상인 경우 </v-col>
                                                            </v-row>
                                                        </div>
                                                    </div>
                                                </td-primary>
                                            </tr>
                                            <tr>
                                                <td-primary>얇은</td-primary>
                                                <td-primary>1,000장</td-primary>
                                            </tr>
                                            <tr>
                                                <td-primary rowspan="3" class="grey lighten-5">직기<br />(셔츠류)</td-primary>
                                                <td-primary>두꺼운</td-primary>
                                                <td-primary>300장</td-primary>
                                            </tr>
                                            <tr>
                                                <td-primary>얇은</td-primary>
                                                <td-primary>500장</td-primary>
                                            </tr>
                                            <tr>
                                                <td-primary>특수원단(고밀도 원단)</td-primary>
                                                <td-primary>재단실과 업체 협의</td-primary>
                                            </tr>
                                            <tr>
                                                <td-primary rowspan="3" class="grey lighten-5">기타</td-primary>
                                                <td-primary>의류 외 재단물</td-primary>
                                                <td-primary>재단실과 업체 협의</td-primary>
                                            </tr>
                                        </tbody>
                                    </template>
                                </table-primary>
                            </v-col>
                            <v-col cols="12">
                                <u-txt-items itemsSize="row--xs">
                                    <v-col cols="12">
                                        <u-txt-items txtItem titCols="auto" txtCols="" class="ma-n2px" colClass="pa-2px" titWidth="" titClass="txt--lg line-height-15" txtClass="txt--lg line-height-15">
                                            <template #tit>7.</template>
                                            <template #txt> 예약한 일자에 아무런 통보 없이 패턴, 원단 등 미입고하여 예약 취소되는 경우에는 그날로부터 7영업일동안 재단예약을 하실 수 없습니다. </template>
                                        </u-txt-items>
                                    </v-col>
                                </u-txt-items>
                            </v-col>
                            <v-col cols="12">
                                <u-txt-items itemsSize="row--xs">
                                    <v-col cols="12">
                                        <u-txt-items txtItem titCols="auto" txtCols="" class="ma-n2px" colClass="pa-2px" titWidth="" titClass="txt--lg line-height-15" txtClass="txt--lg line-height-15">
                                            <template #tit>8.</template>
                                            <template #txt> 재단스케쥴 변경 요청은 불가합니다. </template>
                                        </u-txt-items>
                                    </v-col>
                                </u-txt-items>
                            </v-col>
                            <v-col cols="12">
                                <u-txt-items itemsSize="row--xs">
                                    <v-col cols="12">
                                        <u-txt-items txtItem titCols="auto" txtCols="" class="ma-n2px" colClass="pa-2px" titWidth="" titClass="txt--lg line-height-15" txtClass="txt--lg line-height-15">
                                            <template #tit>9.</template>
                                            <template #txt> 이용수칙을 준수하지 않는 업체는 재단실 이용이 제한될 수 있습니다. </template>
                                        </u-txt-items>
                                    </v-col>
                                </u-txt-items>
                            </v-col>
                        </v-row>
                    </card-primary>
                </page-section-primary>
            </template>
        </page-section-secondary>
    </app-primary>
</template>

<script>
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import PageSectionSecondary from "@/sets/parents/pages/page-section-secondary.vue";
import BtnPrimary from "@/components/publish/parents/buttons/btn-primary.vue";
import ChipPrimary2 from "@/components/publish/parents/chips/chip-primary2.vue";
import ChipPrimary4 from "@/components/publish/parents/chips/chip-primary4.vue";
import CardPrimary from "@/components/publish/parents/cards/card-primary.vue";
import CardSecondary from "@/components/publish/parents/cards/card-secondary.vue";
import TitPrimary from "@/components/publish/parents/typography/tit-primary.vue";
import TxtPrimary from "@/components/publish/parents/typography/txt-primary.vue";
import TablePrimary from "@/components/publish/parents/tables/table-primary.vue";
import ThPrimary from "@/components/publish/parents/tables/th-primary.vue";
import TdPrimary from "@/components/publish/parents/tables/td-primary.vue";

import UTxtItems from "@/components/publish/styles/items/u-txt-items.vue";

export default {
    props: {},
    components: {
        AppPrimary,
        SubHeadPrimary,
        PageSectionPrimary,
        PageSectionSecondary,
        BtnPrimary,
        ChipPrimary2,
        ChipPrimary4,
        CardPrimary,
        CardSecondary,
        TitPrimary,
        TxtPrimary,
        TablePrimary,
        ThPrimary,
        TdPrimary,

        UTxtItems,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.step-border {
    .v-card.v-sheet--outlined {
        position: relative;
        background-color: #fff !important;
        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            z-index: -1;
            -webkit-transform: translate(-100%, -50%);
            -ms-transform: translate(-100%, -50%);
            transform: translate(-100%, -50%);
            display: block;
            width: 68px;
            height: 4px;
            background-color: var(--v-grey-lighten4);
        }
    }
    > [class*="col"] {
        &:first-child {
            .v-card.v-sheet--outlined::before {
                display: none;
            }
        }
    }
}
@media (min-width: 1200px) {
    .step-border {
        .v-card.v-sheet--outlined {
            min-height: 224px;
        }
    }
}
@media (max-width: 1024px) {
    .step-border {
        --grid-gutter-xl: 14px;
    }
}
@media (max-width: 768px) {
    .step-border {
        --grid-gutter-xl: 10px;
    }
}
@media (max-width: 576px) {
    .step-border {
        --grid-gutter-xl: 8px;
        .v-card.v-sheet--outlined::before {
            top: 0;
            left: 50%;
            -webkit-transform: translate(-50%, -100%);
            -ms-transform: translate(-50%, -100%);
            transform: translate(-50%, -100%);
            width: 4px;
            height: 40px;
        }
    }
    ::v-deep {
        .v-data-table {
            > .v-data-table__wrapper > table {
                min-width: 500px;
            }
        }
    }
}
</style>

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-primary', {
    scopedSlots: _vm._u([{
      key: "contentsHead",
      fn: function () {
        return [_c('sub-head-primary', {
          attrs: {
            "itemTitle": "센터소개",
            "tabActive": "오시는 길",
            "bg": "/images/sub/sub-visual.jpg"
          },
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v("센터소개")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('page-section-primary', {
    staticClass: "page-section--first page-section--last",
    attrs: {
      "titWrapClass": "text-center"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v(" 오시는 길 ")];
      },
      proxy: true
    }])
  }, [_c('v-row', {
    staticClass: "row--xl"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('card-primary', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": _vm.$vuetify.breakpoint.lgAndUp ? 600 / 452 : 4 / 2
    }
  }, [_c('iframe', {
    staticStyle: {
      "border": "0"
    },
    attrs: {
      "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3161.998962402453!2d127.02708837621502!3d37.57864212343333!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357cbcad8c79bb6d%3A0x29359110c189de39!2z7ISc7Jq47Yq567OE7IucIOuPmeuMgOusuOq1rCDtlZzruZvroZwgNjI!5e0!3m2!1sko!2skr!4v1702625173350!5m2!1sko!2skr",
      "width": "100%",
      "height": "100%",
      "allowfullscreen": "",
      "loading": "lazy",
      "referrerpolicy": "no-referrer-when-downgrade"
    }
  })])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('div', {
    staticClass: "pa-20px pa-md-40px pa-lg-60px"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('tit-primary', [_vm._v("용신동 패션봉제 복합지원센터")])], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider', {
    staticClass: "primary",
    staticStyle: {
      "border-width": "2px 0 0"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('u-txt-items', {
    attrs: {
      "itemsSize": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('u-txt-items', {
    attrs: {
      "txtItem": "",
      "size": "lg"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_c('v-row', {
          staticClass: "row--xs"
        }, [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('i', {
          staticClass: "icon icon-place"
        })]), _c('v-col', [_vm._v(" Address ")])], 1)];
      },
      proxy: true
    }, {
      key: "txt",
      fn: function () {
        return [_vm._v("동대문구 한빛로 62, 3층~7층")];
      },
      proxy: true
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('u-txt-items', {
    attrs: {
      "txtItem": "",
      "size": "lg"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_c('v-row', {
          staticClass: "row--xs"
        }, [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('i', {
          staticClass: "icon icon-tel"
        })]), _c('v-col', [_vm._v(" Tel ")])], 1)];
      },
      proxy: true
    }, {
      key: "txt",
      fn: function () {
        return [_vm._v("02-6235-2200~1")];
      },
      proxy: true
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('u-txt-items', {
    attrs: {
      "txtItem": "",
      "size": "lg"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_c('v-row', {
          staticClass: "row--xs"
        }, [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('i', {
          staticClass: "icon icon-fax"
        })]), _c('v-col', [_vm._v(" Fax ")])], 1)];
      },
      proxy: true
    }, {
      key: "txt",
      fn: function () {
        return [_vm._v("02-3299-2622")];
      },
      proxy: true
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('u-txt-items', {
    attrs: {
      "txtItem": "",
      "size": "lg"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_c('v-row', {
          staticClass: "row--xs"
        }, [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('i', {
          staticClass: "icon icon-car"
        })]), _c('v-col', [_vm._v(" Parking ")])], 1)];
      },
      proxy: true
    }, {
      key: "txt",
      fn: function () {
        return [_vm._v("지정석 주차장이므로 주차가 안되오니 가급적 대중교통을 이용하여 주시기 바랍니다.")];
      },
      proxy: true
    }])
  })], 1)], 1)], 1)], 1)], 1)])], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('card-primary', [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": _vm.$vuetify.breakpoint.lgAndUp ? 600 / 452 : 4 / 2
    }
  }, [_c('iframe', {
    staticStyle: {
      "border": "0"
    },
    attrs: {
      "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3162.217706522144!2d127.04764891177788!3d37.573490923612134!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357cbb1f83753bd5%3A0x13b72025fdc136af!2z64-Z64yA66y46rWsIOywveyXheyngOybkOyEvO2EsA!5e0!3m2!1sko!2skr!4v1702625093453!5m2!1sko!2skr",
      "width": "100%",
      "height": "100%",
      "allowfullscreen": "",
      "loading": "lazy",
      "referrerpolicy": "no-referrer-when-downgrade"
    }
  })])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('div', {
    staticClass: "pa-20px pa-md-40px pa-lg-60px"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('tit-primary', [_vm._v("답십리 패션봉제지원센터")])], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider', {
    staticClass: "primary",
    staticStyle: {
      "border-width": "2px 0 0"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('u-txt-items', {
    attrs: {
      "itemsSize": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('u-txt-items', {
    attrs: {
      "txtItem": "",
      "size": "lg"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_c('v-row', {
          staticClass: "row--xs"
        }, [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('i', {
          staticClass: "icon icon-place"
        })]), _c('v-col', [_vm._v(" Address ")])], 1)];
      },
      proxy: true
    }, {
      key: "txt",
      fn: function () {
        return [_vm._v("동대문구 답십리로 38길 19, "), _c('br', {
          staticClass: "d-none d-md-block"
        }), _vm._v("창업지원센터 A동·B동 1층")];
      },
      proxy: true
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('u-txt-items', {
    attrs: {
      "txtItem": "",
      "size": "lg"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_c('v-row', {
          staticClass: "row--xs"
        }, [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('i', {
          staticClass: "icon icon-tel"
        })]), _c('v-col', [_vm._v(" Tel ")])], 1)];
      },
      proxy: true
    }, {
      key: "txt",
      fn: function () {
        return [_vm._v("02-2212-5586")];
      },
      proxy: true
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('u-txt-items', {
    attrs: {
      "txtItem": "",
      "size": "lg"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_c('v-row', {
          staticClass: "row--xs"
        }, [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('i', {
          staticClass: "icon icon-fax"
        })]), _c('v-col', [_vm._v(" Fax ")])], 1)];
      },
      proxy: true
    }, {
      key: "txt",
      fn: function () {
        return [_vm._v("02-3299-2622")];
      },
      proxy: true
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('u-txt-items', {
    attrs: {
      "txtItem": "",
      "size": "lg"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_c('v-row', {
          staticClass: "row--xs"
        }, [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('i', {
          staticClass: "icon icon-car"
        })]), _c('v-col', [_vm._v(" Parking ")])], 1)];
      },
      proxy: true
    }, {
      key: "txt",
      fn: function () {
        return [_vm._v("지정석 주차장이므로 주차가 안되오니 가급적 대중교통을 이용하여 주시기 바랍니다.")];
      },
      proxy: true
    }])
  })], 1)], 1)], 1)], 1)], 1)])], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
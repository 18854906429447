var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-primary', {
    scopedSlots: _vm._u([{
      key: "contentsHead",
      fn: function () {
        return [_c('sub-head-primary', {
          attrs: {
            "itemTitle": "센터소개",
            "tabActive": "소개",
            "bg": "/images/sub/sub-visual.jpg"
          },
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v("센터소개")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('page-section-primary', {
    staticClass: "page-section--first page-section--sm",
    attrs: {
      "titWrapClass": "text-center"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v(" 소개 ")];
      },
      proxy: true
    }])
  }, [_c('v-row', {
    staticClass: "row--xl",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "auto"
    }
  }, [_c('v-img', {
    attrs: {
      "width": _vm.$vuetify.breakpoint.lgAndUp ? 486 : '100%',
      "aspect-ratio": _vm.$vuetify.breakpoint.lgAndUp ? 486 / 572 : 6 / 2,
      "position": _vm.$vuetify.breakpoint.lgAndUp ? 'center center' : '50% 20%',
      "src": "/images/sub/intro/intro-introduce-img.jpg"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": ""
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('tit-primary', {
    staticClass: "font-weight-regular"
  }, [_vm._v("동대문구 "), _c('b', {
    staticClass: "primary--text"
  }, [_vm._v("패션 봉제지원센터")])])], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('txt-primary', {
    staticClass: "txt--lg txt--dark line-height-15"
  }, [_vm._v(" 동대문구 제조업의 약 40%를 차지하는 패션봉제산업을 지원하기 위하여 "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v("동대문구에 2개의 패션봉제지원센터를 운영하고 있습니다. ")])], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('txt-primary', {
    staticClass: "txt--lg txt--dark line-height-15"
  }, [_vm._v(" 스마트 제조장비, 공동작업장 운영, 일감연계, 판로개척, 소공인 협업강화, 봉제인력 양성교육 등 다각적인 지원을 통해 패션봉제산업 활성화에 기여하고자 합니다. ")])], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('card-primary', {
    staticClass: "border-4",
    attrs: {
      "cardInnerSize": "card-inner--sm"
    }
  }, [_c('u-txt-items', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('u-txt-items', {
    attrs: {
      "txtItem": "",
      "size": "lg"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("운영시간")];
      },
      proxy: true
    }, {
      key: "txt",
      fn: function () {
        return [_vm._v("매주 월 ~ 금요일 오전 9시 ~ 오후 6시")];
      },
      proxy: true
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('u-txt-items', {
    attrs: {
      "txtItem": "",
      "size": "lg"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("주 소")];
      },
      proxy: true
    }, {
      key: "txt",
      fn: function () {
        return [_vm._v("동대문구 한빛로 62"), _c('br'), _vm._v("동대문구 답십리로 38길 19, 1층")];
      },
      proxy: true
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('u-txt-items', {
    attrs: {
      "txtItem": "",
      "size": "lg"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("휴 관 일")];
      },
      proxy: true
    }, {
      key: "txt",
      fn: function () {
        return [_vm._v("주말 및 공휴일")];
      },
      proxy: true
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('u-txt-items', {
    attrs: {
      "txtItem": "",
      "size": "lg"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("주 차 장")];
      },
      proxy: true
    }, {
      key: "txt",
      fn: function () {
        return [_vm._v("주차장이 협소 하오니 가급적 대중교통을 이용하여 주시기 바랍니다.")];
      },
      proxy: true
    }])
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('page-section-primary', {
    staticClass: "page-section--sm pt-0",
    attrs: {
      "titWrapClass": "text-center"
    },
    scopedSlots: _vm._u([{
      key: "pageSectionFoot",
      fn: function () {
        return [_c('thumbs-slider')];
      },
      proxy: true
    }])
  }), _c('page-section-primary', {
    staticClass: "page-section--last page-section--sm pt-0",
    attrs: {
      "titWrapClass": "text-center"
    }
  }, [_c('table-primary', {
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_c('thead', [_c('tr', [_c('th-primary', {
          staticStyle: {
            "width": "50%"
          },
          attrs: {
            "colspan": "2"
          }
        }, [_vm._v("용신동 패션봉제 복합지원센터")]), _c('th-primary', {
          staticStyle: {
            "width": "50%"
          },
          attrs: {
            "colspan": "2"
          }
        }, [_vm._v("답십리 패션봉제지원센터")])], 1)])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function () {
        return [_c('tbody', [_c('tr', [_c('td-primary', {
          staticClass: "grey lighten-5",
          staticStyle: {
            "width": "20%"
          }
        }, [_vm._v("위치(면적)")]), _c('td-primary', {
          staticClass: "grey lighten-5"
        }, [_vm._v("용도")]), _c('td-primary', {
          staticClass: "grey lighten-5",
          staticStyle: {
            "width": "20%"
          }
        }, [_vm._v("위치(면적)")]), _c('td-primary', {
          staticClass: "grey lighten-5"
        }, [_vm._v("용도")])], 1), _c('tr', [_c('td-primary', [_vm._v("3층(145.59㎡)")]), _c('td-primary', [_vm._v("사무실,전시실,창고")]), _c('td-primary', [_vm._v("A동 1층 –1 (37.22㎡)")]), _c('td-primary', [_vm._v("사무실")])], 1), _c('tr', [_c('td-primary', [_vm._v("4층(145.59㎡)")]), _c('td-primary', [_vm._v("공용재단실")]), _c('td-primary', [_vm._v("A동 1층 –2 (82.54㎡)")]), _c('td-primary', [_vm._v("교육실")])], 1), _c('tr', [_c('td-primary', [_vm._v("5층(145.59㎡)")]), _c('td-primary', [_vm._v("공용장비실1")]), _c('td-primary', [_vm._v("A동 1층 –3 (69.35㎡)")]), _c('td-primary', [_vm._v("공용작업실,전시실")])], 1), _c('tr', [_c('td-primary', [_vm._v("6층(145.59㎡)")]), _c('td-primary', [_vm._v("공용장비실2")]), _c('td-primary', [_vm._v("A동 지하1층 (52.36㎡)")]), _c('td-primary', [_vm._v("창고")])], 1), _c('tr', [_c('td-primary', [_vm._v("7층(122.49㎡)")]), _c('td-primary', [_vm._v("교육실,커뮤니티실")]), _c('td-primary', [_vm._v("B동 1층 (176.8㎡)")]), _c('td-primary', [_vm._v("공용재단실")])], 1)])];
      },
      proxy: true
    }])
  }), _c('table')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
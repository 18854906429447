var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-primary', {
    scopedSlots: _vm._u([{
      key: "contentsHead",
      fn: function () {
        return [_c('sub-head-primary', {
          attrs: {
            "itemTitle": "공용작업실",
            "tabActive": "답십리 신청하기",
            "bg": "/images/sub/sub-visual5.jpg"
          },
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v("공용작업실")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('page-section-secondary', {
    staticClass: "page-section--first page-section--last",
    attrs: {
      "titWrapClass": "text-center"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v(" 개인회원 신청(답십리) ")];
      },
      proxy: true
    }])
  }, [_c('form-input', {
    attrs: {
      "code": "studio-apply",
      "skin": "studio-apply"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <app-primary>
        <template #contentsHead>
            <sub-head-primary itemTitle="공용작업실" tabActive="답십리 신청하기" bg="/images/sub/sub-visual5.jpg">
                <template #tit>공용작업실</template>
            </sub-head-primary>
        </template>

        <page-section-secondary titWrapClass="text-center" class="page-section--first page-section--last">
            <template #tit> 개인회원 신청(답십리) </template>

            <form-input code="studio-apply" skin="studio-apply" />
        </page-section-secondary>
    </app-primary>
</template>

<script>
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import PageSectionSecondary from "@/sets/parents/pages/page-section-secondary.vue";
import BtnPrimary from "@/components/publish/parents/buttons/btn-primary.vue";

import FormInput from "@/components/client/form/form-input.vue";

export default {
    props: {},
    components: {
        AppPrimary,
        SubHeadPrimary,
        PageSectionPrimary,
        PageSectionSecondary,
        BtnPrimary,

        FormInput,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>

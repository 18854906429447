<template>
    <tit-wrap v-bind="$attrs" class="tit-wrap--divider tit-wrap--sm">
        <slot />
    </tit-wrap>
</template>

<script>
import TitWrap from "@/components/publish/styles/typography/tit-wrap.vue";

export default {
    props: {},
    components: {
        TitWrap,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
// Divider
.tit-wrap--divider {
    ::v-deep {
        .tit {
            position: relative;
            display: flex;
            align-items: center;
            &::after {
                content: "";
                display: block;
                width: 40px;
                height: 2px;
                background-color: var(--v-primary-base);
                margin-left: 12px;
            }
            & + .txt {
                margin-top: 20px;
            }
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .tit-wrap--divider {
        ::v-deep {
            .tit {
                &::after {
                    width: 60px;
                }
            }
        }
    }
}
@media (min-width: 1024px) {
    .tit-wrap--divider {
        ::v-deep {
            .tit {
                &::after {
                    width: 100px;
                }
                & + .txt {
                    margin-top: 26px;
                }
            }
        }
    }
}
@media (min-width: 1200px) {
}
</style>

<template>
    <app-primary>
        <template #contentsHead>
            <sub-head-primary itemTitle="공용재단실" tabActive="스케쥴" bg="/images/sub/sub-visual4.jpg">
                <template #tit>공용재단실</template>
            </sub-head-primary>
        </template>

        <page-section-primary titWrapClass="text-center" class="page-section--first page-section--last">
            <template #tit>스케쥴</template>

            <div class="pb-30px pb-md-40px">
                <v-row align="center" justify="center">
                    <v-col cols="auto">
                        <btn-arrow-secondary @click="prev" />
                    </v-col>
                    <v-col cols="auto">
                        <tit-primary v-if="$refs.calendar" class="line-height-1">{{ $refs.calendar.title }}</tit-primary>
                    </v-col>
                    <v-col cols="auto">
                        <btn-arrow-secondary direction="right" @click="next" />
                    </v-col>
                </v-row>
            </div>

            <v-calendar 
                ref="calendar" 
                type="month"
                event-overlap-mode="stack"
                v-model="value" 
                :show-month-on-first="false" 
                :day-format="dayFormat" 
                :events="events" 
                :event-overlap-threshold="30" 
                :event-height="24" 
                :event-margin-bottom="8"
                color="primary" event-text-color="grey" event-color="grey lighten-5" 
                @change="getEvents"
                @click:date="showDetail"
                @click:event="showDetail"
                >

                <template v-slot:event="{ event }">
                    {{ event.name }}
                </template>
            </v-calendar>
        </page-section-primary>
    </app-primary>
</template>

<script>
import api from "@/api";
import { DDM_WORK_TYPES } from '@/assets/variables';
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import BtnArrowSecondary from "@/components/publish/parents/buttons/btn-arrow-secondary.vue";
import TitPrimary from "@/components/publish/parents/typography/tit-primary.vue";

export default {
    components: {
        AppPrimary,
        SubHeadPrimary,
        PageSectionPrimary,
        BtnArrowSecondary,
        TitPrimary,
    },
    data(){
        return{
            value: "",
            events: [],
        }
    },
    mounted() {
    },
    methods: {
        prev() {
            this.$refs.calendar.prev();
        },
        next() {
            this.$refs.calendar.next();
        },
        dayFormat({ day }) {
            return day;
        },
        async getEvents({ start, end }) {
            let events = [];

            // 달력에 표시된 첫 날, 마지막 날
            let startOfMonth = this.$refs.calendar.getStartOfWeek(start).date;
            let endOfMonth = this.$refs.calendar.getEndOfWeek(end).date;
            try{
                let { schedules } = await api.v1.schedule.gets({
                    params:{
                        startOfMonth,
                        endOfMonth,
                        code: DDM_WORK_TYPES.CUTTINGROOM.value
                    }
                });

                schedules.forEach((schedule)=> events.push({ 
                    name: schedule.company, 
                    start: this.$dayjs(schedule.scheduleDate).format("YYYY-MM-DD"),
                    end: this.$dayjs(schedule.scheduleDate).format("YYYY-MM-DD"),
                    // color: "gray lighten-5",
                }));

                this.events = events;
            } catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        showDetail(value){
            const date = value.date || value.day.date;
            this.$router.push(`/cuttingroom/schedule-view?date=${date}`);
        }
    },
};
</script>

<style lang="scss" scoped></style>
